import { shuffle } from "lodash";
import { useCallback } from "react";

import { matchPlayerPath } from "../firestorePaths";

import { SEAT_POSITIONS } from "../constants";
import { Game, Match, MatchPlayer } from "../types/firestoreTypes";
import { useFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";
import {
  selectMe,
  selectMatch,
  selectPlayersForMatch,
  selectMatchGames,
} from "../store/selectors";
import { useCurrentUser } from "./useCurrentUser";

interface ReturnType {
  match: Match | undefined;
  matchListing: Match | undefined;
  join: () => Promise<void>;
  joined: boolean;
  games: Game[] | undefined;
  matchPlayers: MatchPlayer[] | undefined;
}

export const useMatch = ({ matchId }: { matchId: string }): ReturnType => {
  const firestore = useFirestore();
  const match = useSelector(selectMatch(matchId));
  const matchListing = useSelector(selectMatch(matchId));
  const matchPlayers = useSelector(selectPlayersForMatch(matchId));
  const games = useSelector(selectMatchGames(matchId));
  const currentUser = useSelector(selectMe);
  const { setCurrentMatchId } = useCurrentUser();
  console.log("useMatch", { matchPlayers });

  const joined =
    matchPlayers?.find((p) => p.id === currentUser?.id) !== undefined;

  const join = useCallback(async () => {
    if (currentUser === undefined) {
      throw new Error("No current user found; failed to join Match");
    }
    if (joined) {
      setCurrentMatchId(matchId);
      return;
    }

    console.log("useMatch.join", { matchPlayers });

    const occupiedSeats = matchPlayers?.map(({ seat }) => seat);
    const availableSeats = SEAT_POSITIONS.filter(
      (s) => !occupiedSeats?.includes(s)
    );

    if (availableSeats.length === 0) {
      throw new Error("all seats occupied: failed to join Match");
    }

    const path = matchPlayerPath({ matchId, playerId: currentUser.id });
    const matchPlayer: MatchPlayer = {
      matchId,
      id: currentUser.id,
      human: true,
      name: currentUser.name,
      seat: shuffle(availableSeats)[0],
    };
    await firestore.set(path, matchPlayer);

    setCurrentMatchId(matchId);
  }, [
    matchPlayers,
    currentUser,
    firestore,
    matchId,
    joined,
    setCurrentMatchId,
  ]);

  return {
    match,
    matchListing,
    games,
    matchPlayers,
    join,
    joined,
  };
};
