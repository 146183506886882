import React, { useCallback } from "react";
import { chunk, sortBy } from "lodash";

import { useTalon } from "../../../hooks/useTalon";
import { useDeclarer } from "../../../hooks/useDeclarer";

import { Card } from "../shared/Card";

import { Card as CardType } from "../../../types/types";
import {
  selectIAmDeclarer,
  selectCurrentGameTalon,
} from "../../../store/selectors";
import { useSelector } from "react-redux";

export const TalonPicker: React.FC = () => {
  const { takeFromTalon, talonCount } = useTalon();
  const { name: declarerName } = useDeclarer();
  const iAmDeclarer = useSelector(selectIAmDeclarer);
  const talon = useSelector(selectCurrentGameTalon);
  const onClickCardGroup = useCallback(
    async (cards: CardType[]) => {
      if (iAmDeclarer) {
        await takeFromTalon(cards);
      }
    },
    [takeFromTalon, iAmDeclarer]
  );

  if (talon === undefined) {
    return <div>Loading</div>;
  }

  if (!iAmDeclarer) {
    return <div>Waiting for {declarerName} to pick Talon</div>;
  }

  switch (talonCount) {
    case undefined:
      return <div>Something went wrong: talon count not found</div>;
    case 3:
      return (
        <div className="flex space-x-4 items-center justify-center h-full p-10">
          {chunk(
            sortBy(talon, (c) => c.index),
            3
          ).map((cards) => (
            <div
              key={cards.map((c) => c.slug).join(",")}
              className="flex space-x-4 border-gray-400 rounded-md p-2 border-2 border-solid hover:border-lime-400"
              onClick={() => onClickCardGroup(cards)}
            >
              {cards.map((c) => (
                <Card key={c.slug} slug={c.slug} />
              ))}
            </div>
          ))}
        </div>
      );
    case 6:
      return (
        <div
          className="flex space-x-4 border-gray-400 rounded-md p-2 border-2 border-solid hover:border-lime-400"
          onClick={() => onClickCardGroup(talon)}
        >
          {sortBy(talon, (c) => c.index).map((c) => (
            <Card key={c.slug} slug={c.slug} />
          ))}
        </div>
      );
  }
};
