import { useFirestoreConnect } from "react-redux-firebase";
import { usersPath } from "../firestorePaths";

interface Props {
  userId: string;
}

export const useConnectCurrentUser = ({ userId }: Props) => {
  const listeners = [
    {
      collection: usersPath,
      doc: userId,
    },
  ];

  useFirestoreConnect(listeners);
};
