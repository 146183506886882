export const usersPath = "users";

export const userPath = ({ userId }: { userId: string }) =>
  `${usersPath}/${userId}`;

export const matchesPath = "matches";

export const matchPath = ({ matchId }: { matchId: string }) =>
  `${matchesPath}/${matchId}`;

export const gamesPath = ({ matchId }: { matchId: string }) =>
  matchPath({ matchId }) + "/games";

export const gamePath = ({
  gameId,
  matchId,
}: {
  gameId: string;
  matchId: string;
}) => `${gamesPath({ matchId })}/${gameId}`;

export const matchPlayersPath = ({ matchId }: { matchId: string }) =>
  matchPath({ matchId }) + "/matchPlayers";

export const matchPlayerPath = ({
  matchId,
  playerId,
}: {
  matchId: string;
  playerId: string;
}) => `${matchPlayersPath({ matchId })}/${playerId}`;

export const gamePlayersPath = ({
  matchId,
  gameId,
}: {
  matchId: string;
  gameId: string;
}) => gamePath({ matchId, gameId }) + "/gamePlayers";

export const gamePlayerPath = ({
  matchId,
  gameId,
  playerId,
}: {
  gameId: string;
  matchId: string;
  playerId: string;
}) => `${gamePlayersPath({ matchId, gameId })}/${playerId}`;

export const bidsPath = ({
  matchId,
  gameId,
}: {
  matchId: string;
  gameId: string;
}) => gamePath({ matchId, gameId }) + "/bids";

export const bidPath = ({
  gameId,
  matchId,
  bidId,
}: {
  gameId: string;
  matchId: string;
  bidId: string;
}) => `${bidsPath({ matchId, gameId })}/${bidId}`;

export const announcementsPath = ({
  matchId,
  gameId,
}: {
  matchId: string;
  gameId: string;
}) => gamePath({ matchId, gameId }) + "/announcements";

export const announcementPath = ({
  gameId,
  matchId,
  announcementId,
}: {
  gameId: string;
  matchId: string;
  announcementId: string;
}) => `${announcementsPath({ matchId, gameId })}/${announcementId}`;

export const cardsPath = ({
  matchId,
  gameId,
}: {
  matchId: string;
  gameId: string;
}) => gamePath({ matchId, gameId }) + "/cards";

export const cardPath = ({
  matchId,
  gameId,
  cardId,
}: {
  matchId: string;
  gameId: string;
  cardId: string;
}) => `${cardsPath({ matchId, gameId })}/${cardId}`;
