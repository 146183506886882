import { useCallback } from "react";

import { getNextPlayer } from "../helpers/getNextPlayer";

import { GamePlayer } from "../types/firestoreTypes";
import { useSelector } from "react-redux";
import {
  selectCurrentGamePlayers,
  selectForehand,
  selectPartner,
} from "../store/selectors";
import { isLoaded } from "react-redux-firebase";

interface ReturnType {
  loaded: boolean;
  players: GamePlayer[] | undefined;
  forehand: GamePlayer | undefined;
  partner: GamePlayer | undefined;
  getPlayerById: (playerId: string) => GamePlayer | undefined;
  getNextPlayerFromId: (playerId: string) => GamePlayer | undefined;
}

export const useGamePlayers = (): ReturnType => {
  const players = useSelector(selectCurrentGamePlayers);
  const partner = useSelector(selectPartner);
  const forehand = useSelector(selectForehand);

  const getPlayerById = useCallback(
    (playerId: string) => {
      return players?.find((p) => p.id === playerId);
    },
    [players]
  );

  const getNextPlayerFromId = useCallback(
    (playerId: string) => {
      if (players === undefined) {
        throw new Error("Failed to get next player from ID; no players found");
      }
      return getNextPlayer({ nextFromPlayerId: playerId, players });
    },
    [players]
  );

  return {
    loaded: isLoaded(players),
    players,
    forehand,
    getPlayerById,
    getNextPlayerFromId,
    partner,
  };
};
