// Add Firebase to reducers
import { combineReducers } from "redux";
import { firestoreReducer } from "redux-firestore";
import { ACTION_TYPE } from "./actions";
import { StoreState } from "../types/types";

export const reducer = combineReducers<StoreState>({
  firestore: firestoreReducer,
  currentUserId: (state, action) => {
    switch (action.type) {
      case ACTION_TYPE.SET_CURRENT_USER_ID:
        return action.payload;
      case ACTION_TYPE.CLEAR_CURRENT_USER_ID:
        return null;
    }

    return state ?? null;
  },
  localPutdowns: (state, action) => {
    switch (action.type) {
      case ACTION_TYPE.ADD_LOCAL_PUTDOWN: {
        return [...(state ?? []), action.payload];
      }
      case ACTION_TYPE.REMOVE_LOCAL_PUTDOWN: {
        return [...(state ?? []).filter((c) => c.id !== action.payload.id)];
      }
      case ACTION_TYPE.CLEAR_LOCAL_PUTDOWNS: {
        return [];
      }
    }
    return state ?? [];
  },
});
