import {
  EnrichedMadeBid,
  GameStage,
  HandCard,
  MyHandCard,
} from "../types/types";
import { isLoaded } from "react-redux-firebase";
import { useSelector } from "react-redux";
import {
  selectMyUserId,
  selectDeclarerId,
  selectGamePlayer,
  selectPlayerBids,
  selectMyHandCards,
  selectGameStage,
  selectActiveTrickPlayer,
  selectIAmDeclarer,
  selectViewingTrickIndex,
} from "../store/selectors";
import { useCallback, useMemo } from "react";
import { useTricks } from "./useTricks";
import { usePutdowns } from "./usePutdowns";
import { isValidPutdown } from "../helpers/isValidPutdown";

interface ReturnType {
  handCards: MyHandCard[] | undefined;
  bids: EnrichedMadeBid[] | undefined;
  name: string | undefined;
  forehand: boolean;
  human: boolean | undefined;
  loaded: boolean;
  isDeclarer: boolean;
}

export const useMe = (): ReturnType => {
  const playerId = useSelector(selectMyUserId);
  const bids = useSelector(selectPlayerBids(playerId));
  const gamePlayer = useSelector(selectGamePlayer(playerId));
  const rawHandCards = useSelector(selectMyHandCards);
  const gameStage = useSelector(selectGameStage);
  const declarerId = useSelector(selectDeclarerId);
  const activeTrickPlayer = useSelector(selectActiveTrickPlayer);
  const iAmDeclarer = useSelector(selectIAmDeclarer);
  const viewingTrickIndex = useSelector(selectViewingTrickIndex);
  const { putdownCard } = usePutdowns();

  const {
    playCard,
    isCardValid: isCardValidForTrick,
    playableTrickIndex,
  } = useTricks();

  const onCardClick = useMemo(() => {
    switch (gameStage) {
      case GameStage.Bidding:
      case GameStage.RuferOptions:
      case GameStage.PickKing:
      case GameStage.Finished:
      case undefined:
        return;
      case GameStage.Tricks:
        if (
          playerId !== undefined &&
          activeTrickPlayer?.id === playerId &&
          viewingTrickIndex === playableTrickIndex
        ) {
          return (card: HandCard) => playCard({ card });
        }
        return;
      case GameStage.Putdown:
        return (card: HandCard) => putdownCard(card);
    }
  }, [
    viewingTrickIndex,
    putdownCard,
    gameStage,
    activeTrickPlayer?.id,
    playerId,
    playCard,
    playableTrickIndex,
  ]);

  const isCardEnabled = useCallback(
    (card: HandCard): boolean => {
      switch (gameStage) {
        case GameStage.Dealing:
        case GameStage.Bidding:
        case GameStage.RuferOptions:
        case GameStage.PickKing:
        case GameStage.Announcements:
        case GameStage.Finished:
        case GameStage.Talon:
        case undefined:
          return false;
        case GameStage.Tricks:
          return (
            activeTrickPlayer?.id === playerId &&
            viewingTrickIndex === playableTrickIndex &&
            isCardValidForTrick(card.slug)
          );
        case GameStage.Putdown:
          return (
            iAmDeclarer &&
            rawHandCards !== undefined &&
            isValidPutdown(card.slug, rawHandCards)
          );
      }
    },
    [
      gameStage,
      isCardValidForTrick,
      activeTrickPlayer,
      playerId,
      viewingTrickIndex,
      playableTrickIndex,
      rawHandCards,
      iAmDeclarer,
    ]
  );

  const handCards = useMemo(() => {
    return rawHandCards?.map((card) => {
      return {
        ...card,
        onClick: onCardClick,
        isEnabled: isCardEnabled(card),
      };
    });
  }, [rawHandCards, onCardClick, isCardEnabled]);

  return {
    handCards,
    bids,
    name: gamePlayer?.name,
    forehand: gamePlayer?.gamePosition === 0,
    human: gamePlayer?.human,
    loaded: isLoaded(gamePlayer),
    isDeclarer: declarerId === playerId,
  };
};
