import React from "react";

import { Button } from "@mui/material";

import { useMatches } from "../hooks/useMatches";

import { MatchListing } from "./matchPicker/MatchListing";
import { useSelector } from "react-redux";
import { selectMatches } from "../store/selectors";

interface Props {
  userId: string;
}

export const MatchPicker: React.FC<Props> = ({ userId }) => {
  const matchListings = useSelector(selectMatches);
  const { createMatch } = useMatches();

  console.log("MatchPicker", { matchListings });

  if (matchListings === undefined) {
    return null;
  }

  return (
    <div className="flex flex-col items-start space-y-2">
      {matchListings.map((match) => {
        return <MatchListing matchId={match.id} key={match.name} />;
      })}
      <Button
        variant="contained"
        onClick={async () => await createMatch({ humanPlayerCount: 1 })}
      >
        create Match
      </Button>
    </div>
  );
};
