import {
  AnnouncementSlug,
  BidSlug,
  KontraSlug,
  RuferOptionSlug,
  SUITS,
} from "../constants";
import {
  Card,
  CardSlug,
  FirestoreOrderedStoreState,
  FirestoreStoreState,
  Game,
  GamePlayer,
  MadeBid,
} from "./firestoreTypes";

export interface StoreState {
  currentUserId: string | null;
  localPutdowns: Card[];
  firestore: {
    data: FirestoreStoreState;
    ordered: FirestoreOrderedStoreState;
  };
}

export type BidDetails = {
  slug: BidSlug;
  name: string;
  points: number;
  pickTalon?: boolean;
  pickKing?: boolean;
  ruferSubBid?: boolean;
};

export type EnrichedMadeBid = BidDetails & MadeBid;

export interface HandCard extends Card {
  playerId: string;
}

export interface MyHandCard extends HandCard {
  onClick?: (card: HandCard) => void;
  isEnabled: boolean;
}

export interface TrickCard extends HandCard {
  trickIndex: number;
  cardIndex: number;
}

export type Suit = typeof SUITS[number];
export enum Size {
  XL = "xl",
  L = "l",
  M = "m",
  S = "s",
  XS = "xs",
  XXS = "xxs",
}

export type WonBidSlug =
  | Exclude<BidSlug, BidSlug.Pass | BidSlug.Rufer>
  | RuferOptionSlug;

export * from "./firestoreTypes";
export { GameStage } from "../constants";

export type Announcement =
  | { slug: AnnouncementSlug; base: null }
  | { slug: KontraSlug; base: AnnouncementSlug | "Game" };

export type MadeAnnouncement = Announcement & {
  matchId: string;
  gameId: string;
  index: number;
  playerId: string;
};

export interface User {
  id: string;
  name: string;
  currentMatchId: string | null;
  currentGameId: string | null;
  viewingTrickIndex?: number;
  viewingScores: boolean;
}

export interface Team {
  players: GamePlayer[];
  declarers: boolean;
}

export interface TeamScore {
  players: GamePlayer[];
  declarers: boolean;
  trickCards: TrickCard[];
  talonCards: CardSlug[] | undefined;
  putdownCards: CardSlug[] | undefined;
  cardPoints: number;
  matchPoints: number;
  winner: boolean;
}

export interface GamePlayerOutcome extends GamePlayer {
  score: number;
  isDeclarer: boolean;
}

export interface AnnouncementOutcome {
  slug: AnnouncementSlug;
  made: boolean;
  kontra?: KontraSlug;
}

export interface GameOutcome extends Game {
  players: GamePlayerOutcome[];
  bid: { slug: WonBidSlug; made: boolean; kontra?: KontraSlug };
  announcements: AnnouncementOutcome[];
}

export interface CumulativeGameOutcome extends GameOutcome {
  players: (GamePlayerOutcome & { cumulativeScore: number })[];
}
