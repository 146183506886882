import {EnrichedMadeBid, HandCard } from "../types/types";
import { isLoaded } from "react-redux-firebase";
import { useSelector } from "react-redux";
import {
  selectDeclarerId,
  selectGamePlayer,
  selectPlayerBids,
  selectPlayerHandCards,
} from "../store/selectors";

interface ReturnType {
  handCards: HandCard[] | undefined;
  bids: EnrichedMadeBid[] | undefined;
  name: string | undefined;
  forehand: boolean;
  human: boolean | undefined;
  loaded: boolean;
  isDeclarer: boolean
}

export const useGamePlayer = ({
  playerId,
}: {
  playerId: string | undefined;
}): ReturnType => {
  const bids = useSelector(selectPlayerBids(playerId));
  const gamePlayer = useSelector(selectGamePlayer(playerId));
  const handCards = useSelector(selectPlayerHandCards(playerId));
  const declarerId = useSelector(selectDeclarerId)

  return {
    handCards,
    bids,
    name: gamePlayer?.name,
    forehand: gamePlayer?.gamePosition === 0,
    human: gamePlayer?.human,
    loaded: isLoaded(gamePlayer),
    isDeclarer: declarerId === playerId
  };
};
