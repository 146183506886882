import React from "react";

import { Player } from "./game/Player";
import { Me } from "./game/Me";
import { Table } from "./game/Table";
import { GameStage } from "../types/types";
import { Finished } from "./game/Finished";
import {
  selectCurrentGameId,
  selectCurrentMatchId,
  selectGameIndex,
  selectGameStage,
} from "../store/selectors";
import { useSelector } from "react-redux";
import { usePositionedGamePlayerIds } from "../hooks/usePositionedGamePlayerIds";
import { useConnectGame } from "../hooks/useConnectGame";
import { GameDetails } from "./game/GameDetails";

const ConnectedGame: React.FC = () => {
  const stage = useSelector(selectGameStage);
  const positionedPlayerIds = usePositionedGamePlayerIds();
  const gameIndex = useSelector(selectGameIndex);

  if (
    stage === undefined ||
    positionedPlayerIds === undefined ||
    gameIndex === undefined
  ) {
    return <div>loading stage and positionedPlayerIds</div>;
  }

  if (stage === GameStage.Finished) {
    return <Finished />;
  }

  return (
    <div className="grid h-full w-full p-4 grid-rows-3 grid-cols-4 gap-4">
      <div className="row-start-1 col-start-1 col-span-3 flex">
        <Table />
      </div>
      <div className="row-start-2 col-start-1 col-span-3">
        <Me />
      </div>
      <div className="row-start-3 col-start-1">
        <Player playerId={positionedPlayerIds.firstFromMe} />
      </div>
      <div className="row-start-3 col-start-2">
        <Player playerId={positionedPlayerIds.secondFromMe} />
      </div>
      <div className="row-start-3 col-start-3">
        <Player playerId={positionedPlayerIds.thirdFromMe} />
      </div>
      <div className="row-start-1 col-start-4 row-span-full flex">
        <GameDetails />
      </div>
    </div>
  );
};

const ConnectGame: React.FC<{ matchId: string; gameId: string }> = ({
  matchId,
  gameId,
  children,
}) => {
  const gameConnectionStatus = useConnectGame({ matchId, gameId });
  switch (gameConnectionStatus) {
    case "error":
      return <div>'Failed to connect to Firestore'</div>;
    case "success":
      return <>{children}</>;
  }
};

export const Game: React.FC = () => {
  const matchId = useSelector(selectCurrentMatchId);
  const gameId = useSelector(selectCurrentGameId);

  if (matchId === undefined || gameId === undefined) {
    return <div>loading matchId and gameId</div>;
  }

  return (
    <ConnectGame matchId={matchId} gameId={gameId}>
      <ConnectedGame />
    </ConnectGame>
  );
};
