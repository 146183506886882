import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";

import { userPath } from "../firestorePaths";

import { selectMyUserId } from "../store/selectors";
import { User } from "../types/types";

interface ReturnType {
  setCurrentMatchId: (matchId: string | null) => void;
  setCurrentGameId: (gameId: string | null) => void;
  clearSelectedMatch: () => void;
  setViewingTrickIndex: (viewingTrickIndex: number | null) => void;
  setViewingScores: (viewingScores: boolean) => void;
}

export const useCurrentUser = (): ReturnType => {
  const firestore = useFirestore();
  const currentUserId = useSelector(selectMyUserId);

  const setCurrentGameId = useCallback(
    async (gameId: string | null) => {
      if (currentUserId === undefined || currentUserId === null) {
        throw new Error("Tried to set currentGameId on undefined user");
      }
      const path = userPath({ userId: currentUserId });
      return firestore.update(path, { currentGameId: gameId });
    },
    [firestore, currentUserId]
  );

  const setCurrentMatchId = useCallback(
    async (matchId: string | null) => {
      if (currentUserId === undefined) {
        throw new Error("Tried to set currentMatchId on undefined user");
      }
      const path = userPath({ userId: currentUserId });

      return firestore.update<User>(path, { currentMatchId: matchId });
    },
    [currentUserId, firestore]
  );

  const setViewingTrickIndex = useCallback(
    async (viewingTrickIndex: number | null) => {
      if (currentUserId === undefined) {
        throw new Error("Tried to set viewingTrickIndex on undefined user");
      }

      const path = userPath({ userId: currentUserId });
      return firestore.update(path, { viewingTrickIndex });
    },
    [currentUserId, firestore]
  );

  const setViewingScores = useCallback(
    async (viewingScores: boolean) => {
      if (currentUserId === undefined) {
        throw new Error("Tried to set viewingTrickIndex on undefined user");
      }

      const path = userPath({ userId: currentUserId });
      return firestore.update(path, { viewingScores });
    },
    [currentUserId, firestore]
  );

  const clearSelectedMatch = async () => {
    if (currentUserId === undefined) {
      return;
    }
    const path = userPath({ userId: currentUserId });
    await firestore.update(path, {
      currentGameId: null,
      currentMatchId: null,
    });
  };

  return {
    setCurrentGameId,
    setCurrentMatchId,
    setViewingTrickIndex,
    setViewingScores,
    clearSelectedMatch,
  };
};
