import React from "react";
import { sortBy } from "lodash";
import Button from "@mui/material/Button";

import { useCurrentGame } from "../../../hooks/useCurrentGame";
import { useDeclarer } from "../../../hooks/useDeclarer";
import { RUFER_OPTIONS } from "../../../constants";
import { selectIAmDeclarer } from "../../../store/selectors";
import { useSelector } from "react-redux";

export const RuferOptionPicker: React.FC = () => {
  const { setRuferOption } = useCurrentGame();
  const { declarer } = useDeclarer();

  const iAmDeclarer = useSelector(selectIAmDeclarer);

  // should never happen
  if (declarer === undefined) {
    return <div>Loading declarer</div>;
  }

  if (!iAmDeclarer) {
    return <div>Waiting for {declarer.name} to pick a Rufer option</div>;
  }

  return (
    <div className="flex flex-wrap items-center justify-center h-full space-x-2">
      {sortBy(RUFER_OPTIONS, ({ points }) => points).map((bid) => (
        <Button
          key={bid.slug}
          variant="outlined"
          className="!rounded-full"
          onClick={() => setRuferOption(bid.slug)}
        >
          {bid.name}
        </Button>
      ))}
    </div>
  );
};
