import React from "react";

import { sortHand } from "../../helpers/sortHand";

import { HandCard } from "./me/HandCard";
import { useMe } from "../../hooks/useMe";
import { PlayerDetails } from "./PlayerDetails";
import { useSelector } from "react-redux";
import { selectLocalPutdowns, selectMyUserId } from "../../store/selectors";

export const Me: React.FC = () => {
  const { handCards, bids } = useMe();
  const playerId = useSelector(selectMyUserId);
  const localPutdowns = useSelector(selectLocalPutdowns);

  if (handCards === undefined || bids === undefined || playerId === undefined) {
    return null;
  }

  return (
    <div className="flex flex-col h-full w-full space-y-6">
      <PlayerDetails playerId={playerId} />
      <div className="flex -space-x-2 min-h-0 min-w-0">
        {sortHand(handCards)
          .filter((c) => !localPutdowns.map((c) => c.id).includes(c.id))
          .map((card) => (
            <HandCard key={`me-handcard-${card.slug}`} card={card} />
          ))}
      </div>
    </div>
  );
};
