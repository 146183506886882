import {MadeBid} from "../types/firestoreTypes";
import {EnrichedMadeBid} from "../types/types";
import {BIDS} from "../constants";

export const enrichMadeBid = (madeBid: MadeBid): EnrichedMadeBid => {
    const bidDetails = BIDS.find(bd => bd.slug === madeBid.slug)
    if (bidDetails === undefined) {
        throw new Error(`Failed to enrich Bid ${madeBid.slug}`)
    }
    return {...bidDetails, ...madeBid}
}