import { useCallback, useMemo } from "react";

import { Card } from "../types/firestoreTypes";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentGameTalon,
  selectLocalPutdowns,
} from "../store/selectors";
import { useUpdateCard } from "./useUpdateCard";
import {
  addLocalPutdown,
  clearLocalPutdowns,
  removeLocalPutdown,
} from "../store/actions";

interface ReturnType {
  setPutdownsFinished: () => Promise<void>;

  putdowns: Card[];
  canBeFinished: boolean;

  putdownCard: (card: Card) => void;
  pickupCard: (card: Card) => void;

  requiredPutdownCount: number;
}

export const usePutdowns = (): ReturnType => {
  const updateCard = useUpdateCard();
  const dispatch = useDispatch();
  const talon = useSelector(selectCurrentGameTalon);

  const putdowns = useSelector(selectLocalPutdowns);
  const setPutdownsFinished = useCallback(async () => {
    await Promise.all(
      putdowns.map((card) => updateCard(card.id, { isPutdown: true }))
    );
    dispatch(clearLocalPutdowns);
  }, [updateCard, putdowns, dispatch]);

  const canBeFinished = useMemo(() => {
    if (talon?.length === undefined || putdowns?.length === undefined) {
      return false;
    }

    return 6 - talon?.length === putdowns.length;
  }, [talon?.length, putdowns?.length]);

  const putdownCard = useCallback(
    (card: Card) => {
      dispatch(addLocalPutdown(card));
    },
    [dispatch]
  );

  const pickupCard = useCallback(
    (card: Card) => {
      dispatch(removeLocalPutdown(card));
    },
    [dispatch]
  );

  return {
    putdowns,
    setPutdownsFinished,
    canBeFinished,
    putdownCard,
    pickupCard,
    requiredPutdownCount: 6 - talon.length,
  };
};
