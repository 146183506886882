import React from "react";
import { LoadingButton } from "@mui/lab";

import { HiddenCard } from "./shared/HiddenCard";
import { useGamePlayer } from "../../hooks/useGamePlayer";
import { PlayerDetails } from "./PlayerDetails";

interface Props {
  playerId: string;
}

export const Player: React.FC<Props> = ({ playerId }) => {
  const { handCards, name } = useGamePlayer({ playerId });

  if (handCards === undefined || name === undefined) {
    return <LoadingButton />;
  }

  return (
    <div className="flex flex-col items-start justify-end items-center">
      <PlayerDetails playerId={playerId} />
      <div className="flex -space-x-10">
        {handCards.map((_card, i) => (
          <HiddenCard key={`playerId-handcard-${i}`} />
        ))}
      </div>
    </div>
  );
};
