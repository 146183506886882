import React from "react";

import { useCurrentGame } from "../../hooks/useCurrentGame";
import { useGamePlayers } from "../../hooks/useGamePlayers";
import { useDeclarer } from "../../hooks/useDeclarer";

import { BidPicker } from "./table/BidPicker";
import { RuferOptionPicker } from "./table/RuferOptionPicker";
import { KingPicker } from "./table/KingPicker";
import { TalonPicker } from "./table/TalonPicker";
import { PutdownPicker } from "./table/PutdownPicker";
import { Tricks } from "./table/Tricks";

import { GameStage } from "../../types/types";
import { selectGameStage } from "../../store/selectors";
import { useSelector } from "react-redux";
import { AnnouncementPicker } from "./AnnouncementPicker";

// represents the middle of the screen
export const Table: React.FC = () => {
  const { game } = useCurrentGame();
  const stage = useSelector(selectGameStage);
  const { players } = useGamePlayers();
  const { declarer } = useDeclarer();

  // TODO return an 'all loaded' prop or something to prevent this nastiness
  if (game === undefined || stage === undefined || players === undefined) {
    return <div>loading</div>;
  }

  switch (stage) {
    case GameStage.Dealing:
      return <div>dealing game...</div>;
    case GameStage.Bidding:
      return <BidPicker />;
    case GameStage.RuferOptions:
      return <RuferOptionPicker />;
    case GameStage.PickKing:
      return <KingPicker />;
    case GameStage.Talon:
      if (declarer === undefined) {
        return <div> loading</div>;
      }
      return <TalonPicker />;
    case GameStage.Putdown:
      return <PutdownPicker />;
    case GameStage.Announcements:
      return <AnnouncementPicker />;
    case GameStage.Tricks:
      return <Tricks />;
    case GameStage.Finished:
      // TODO: types
      return null;
  }
};
