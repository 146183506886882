import React from "react";
import { Button } from "@mui/material";

import { usePutdowns } from "../../../hooks/usePutdowns";
import { useGamePlayer } from "../../../hooks/useGamePlayer";
import { useDeclarer } from "../../../hooks/useDeclarer";
import { Card } from "../shared/Card";
import { useSelector } from "react-redux";
import { selectMyUserId, selectIAmDeclarer } from "../../../store/selectors";
import { ActionHeaderText } from "../shared/ActionHeaderText";

export const PutdownPicker: React.FC = () => {
  const {
    putdowns,
    pickupCard,
    setPutdownsFinished,
    canBeFinished,
    requiredPutdownCount,
  } = usePutdowns();

  const activePlayerId = useSelector(selectMyUserId);
  const { loaded: gamePlayerLoaded } = useGamePlayer({
    playerId: activePlayerId,
  });
  const iAmDeclarer = useSelector(selectIAmDeclarer);

  const { loaded: declarerLoaded, name: declarerName } = useDeclarer();

  if (!gamePlayerLoaded || !declarerLoaded || putdowns === undefined) {
    return <div>loading...</div>;
  }

  if (!iAmDeclarer) {
    return <div>Waiting for {declarerName} to put down cards</div>;
  }

  return (
    <div>
      {/* TODO Secherdreier*/}
      <ActionHeaderText>
        Put down {requiredPutdownCount} cards to continue
      </ActionHeaderText>
      <div className="flex space-x-2 items-center justify-center h-full">
        {putdowns.map((c) => (
          <Card
            onClick={() => pickupCard(c)}
            key={c.slug}
            slug={c.slug}
            enabled
          />
        ))}
        {canBeFinished && (
          <Button onClick={setPutdownsFinished}>
            Confirm cards to put down
          </Button>
        )}
      </div>
    </div>
  );
};
