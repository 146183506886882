import { useFirestoreConnect } from "react-redux-firebase";
import { usersPath } from "../firestorePaths";

export const useConnectUsers = () => {
  const listeners = [
    {
      collection: usersPath,
    },
  ];
  useFirestoreConnect(listeners);
};
