import { useSelector } from "react-redux";
import { selectGameIndex } from "../../../store/selectors";
import { LetterIcon } from "../shared/icons/LetterIcon";
import { yellow } from "@mui/material/colors";
import React from "react";

export const HandDetails = () => {
  const gameIndex = useSelector(selectGameIndex);
  return (
    <div className="flex items-center gap-4">
      <LetterIcon color={yellow[500]} title="Hand" />
      <span>{(gameIndex ?? 0) + 1}</span>
    </div>
  );
};
