import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectCurrentGamePlayers, selectMyUserId } from "../store/selectors";

type ReturnType =
  | {
      thirdFromMe: string;
      secondFromMe: string;
      firstFromMe: string;
      me: string;
    }
  | undefined;

export const usePositionedGamePlayerIds = (): ReturnType => {
  const players = useSelector(selectCurrentGamePlayers);
  const userId = useSelector(selectMyUserId);

  console.log("usePositionedGamePlayerIds", { players, userId });

  return useMemo(() => {
    if (players.length !== 4) {
      return undefined;
    }

    const me = players.find((p) => p.id === userId);
    if (me === undefined) {
      return undefined;
    }

    const east = players.find(
      (p) => p.gamePosition === (me.gamePosition + 1) % 4
    );
    const north = players.find(
      (p) => p.gamePosition === (me.gamePosition + 2) % 4
    );
    const west = players.find(
      (p) => p.gamePosition === (me.gamePosition + 3) % 4
    );

    if (east === undefined || north === undefined || west === undefined) {
      throw new Error("opponents not found");
    }

    return {
      me: me.id,
      firstFromMe: east.id,
      secondFromMe: north.id,
      thirdFromMe: west.id,
    };
  }, [players, userId]);
};
