import { Announcement, BidDetails } from "./types/types";

export enum BidSlug {
  Pass = "pass",
  Rufer = "rufer",
  Solo = "solo",
  Piccolo = "piccolo",
  BesserRufer = "besser_rufer",
  Dreier = "dreier",
  Bettel = "bettel",
  SoloDreier = "solo_dreier",
}

export enum AnnouncementSlug {
  Pass = "pass",
  FortyFive = "45",
  Valat = "valat",
  Pagat = "pagat",
  King = "king",
  Uhu = "uhu",
  Kakadu = "kakadu",
}

export enum KontraSlug {
  Kontra = "kontra",
  Rekontra = "rekontra",
  Subkontra = "subkontra",
}

export const BASE_ANNOUNCEMENTS: Announcement[] = [
  { slug: AnnouncementSlug.Pass, base: null },
  { slug: AnnouncementSlug.FortyFive, base: null },
  { slug: AnnouncementSlug.King, base: null },
  { slug: AnnouncementSlug.Pagat, base: null },
  { slug: AnnouncementSlug.Uhu, base: null },
  { slug: AnnouncementSlug.Kakadu, base: null },
  { slug: AnnouncementSlug.Valat, base: null },
];

export const KONTRA_GAME_ANNOUNCEMENT: Announcement = {
  slug: KontraSlug.Kontra,
  base: "Game",
};

export enum RuferOptionSlug {
  CallKing = "call_king",
  Trischaken = "trischaken",
  Sechserdreier = "sechserdreier",
}

export const BID_SLUGS = Object.values(BidSlug);

export const RUFER_OPTION_SLUGS = Object.values(RuferOptionSlug);

export const BIDS: BidDetails[] = [
  { slug: BidSlug.Pass, name: "Pass", points: 0 },
  { slug: BidSlug.Rufer, name: "Rufer", points: 1 },
  { slug: BidSlug.Solo, name: "Solo", points: 2, pickKing: true },
  { slug: BidSlug.Dreier, name: "Dreier", points: 3, pickTalon: true },
  {
    slug: BidSlug.BesserRufer,
    name: "Besser Rufer",
    points: 1,
    pickTalon: true,
    pickKing: true,
  },
  { slug: BidSlug.SoloDreier, name: "Solo Dreier", points: 6 },
  { slug: BidSlug.Piccolo, name: "Piccolo", points: 2 },
  { slug: BidSlug.Bettel, name: "Bettel", points: 3 },
];

interface RuferOption {
  slug: RuferOptionSlug;
  name: string;
  points: number;
  pickTalon?: boolean;
  pickKing?: boolean;
  ruferSubBid?: boolean;
}

export const RUFER_OPTIONS: RuferOption[] = [
  {
    slug: RuferOptionSlug.CallKing,
    name: "Call a King",
    points: 1,
    pickTalon: true,
    pickKing: true,
    ruferSubBid: true,
  },
  {
    slug: RuferOptionSlug.Trischaken,
    name: "Trischaken",
    points: 1,
    ruferSubBid: true,
  },
  {
    slug: RuferOptionSlug.Sechserdreier,
    name: "Sechserdreier",
    points: 3,
    pickTalon: true,
    ruferSubBid: true,
  },
];

export const SEAT_POSITIONS = [0, 1, 2, 3];

export const DIAMOND_KING = "diamond_8";
const SPADE_KING = "spade_8";
const HEART_KING = "heart_8";
const CLUB_KING = "club_8";

export const KING_SLUGS = [DIAMOND_KING, SPADE_KING, HEART_KING, CLUB_KING];

export const TRUMP = "trump";
export const SUITS = ["diamond", "spade", "heart", "club", TRUMP];

export const DECK: string[] = [
  "diamond_1",
  "diamond_2",
  "diamond_3",
  "diamond_4",
  "diamond_5",
  "diamond_6",
  "diamond_7",
  DIAMOND_KING,
  "heart_1",
  "heart_2",
  "heart_3",
  "heart_4",
  "heart_5",
  "heart_6",
  "heart_7",
  HEART_KING,
  "spade_1",
  "spade_2",
  "spade_3",
  "spade_4",
  "spade_5",
  "spade_6",
  "spade_7",
  SPADE_KING,
  "club_1",
  "club_2",
  "club_3",
  "club_4",
  "club_5",
  "club_6",
  "club_7",
  CLUB_KING,
  "trump_1",
  "trump_2",
  "trump_3",
  "trump_4",
  "trump_5",
  "trump_6",
  "trump_7",
  "trump_8",
  "trump_9",
  "trump_10",
  "trump_11",
  "trump_12",
  "trump_13",
  "trump_14",
  "trump_15",
  "trump_16",
  "trump_17",
  "trump_18",
  "trump_19",
  "trump_20",
  "trump_21",
  "trump_22",
];

export enum GameStage {
  Dealing = "Dealing",
  Bidding = "Bidding",
  RuferOptions = "RuferOptions",
  PickKing = "PickKing",
  Talon = "Talon",
  Putdown = "Putdown",
  Announcements = "Announcements",
  Tricks = "Tricks",
  Finished = "Finished",
}
