import { useSelector } from "react-redux";

import { Card } from "../types/firestoreTypes";
import { selectDeclarerId, selectTalonExchangeCount } from "../store/selectors";

import { useCurrentGame } from "./useCurrentGame";
import { useUpdateCard } from "./useUpdateCard";

interface ReturnType {
  loaded: boolean;
  takeFromTalon: (card: Card[]) => Promise<void>;
  talonCount: undefined | 3 | 6;
}

export const useTalon = (): ReturnType => {
  const { loaded: gameLoaded } = useCurrentGame();
  const updateCard = useUpdateCard();
  const declarerId = useSelector(selectDeclarerId);
  const talonCount = useSelector(selectTalonExchangeCount);

  const takeFromTalon = async (cards: Card[]): Promise<void> => {
    if (declarerId === undefined) {
      return;
    }
    await Promise.all(
      cards.map((card) => updateCard(card.id, { playerId: declarerId }))
    );
  };

  return {
    loaded: gameLoaded,
    takeFromTalon,
    talonCount,
  };
};
