import React, { useCallback } from "react";
import { Button, Chip } from "@mui/material";
import { orderBy, groupBy, flatten } from "lodash";

import { getCardPoints } from "../../helpers/getCardPoints";

import { Card } from "./shared/Card";

import { Size } from "../../types/types";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { useCurrentMatch } from "../../hooks/useCurrentMatch";
import { useSelector } from "react-redux";
import {
  selectCurrentGameTeamScores,
  selectCurrentGameTalon,
} from "../../store/selectors";

// TODO: display something to say if we've won
export const Finished: React.FC = () => {
  const teamScores = useSelector(selectCurrentGameTeamScores);
  const talon = useSelector(selectCurrentGameTalon);
  const { setCurrentGameId } = useCurrentUser();
  const { openGame } = useCurrentMatch();
  const gotoNextGame = useCallback(async () => {
    if (openGame !== undefined) {
      setCurrentGameId(openGame.id);
    } else {
      throw new Error("No game dealt yet");
    }
  }, [openGame, setCurrentGameId]);
  // TODO: false in Trischaken
  const talonNotAwarded = false;

  if (teamScores === undefined || talon === undefined) {
    return <div>loading...</div>;
  }

  return (
    <div className="flex space-x-10 p-10">
      <div className="flex flex-col space-y-10">
        {talonNotAwarded && (
          <div>
            talon
            <div className="flex -space-x-3">
              {flatten(talon).map((c) => (
                <Card slug={c.slug} size={Size.XS} key={c.slug} />
              ))}
            </div>
            <div>
              talon points: {getCardPoints(flatten(talon.map((c) => c.slug)))}
            </div>
          </div>
        )}
        {teamScores.map((ts) => {
          const groupedTricks = Object.values(
            groupBy(orderBy(ts.trickCards, "trickIndex"), "trickIndex")
          );
          return (
            <div
              className="flex space-x-6"
              key={ts.players.map((p) => p.name).join(",")}
            >
              <div className="">
                <h2 className="text-lg font-bold mb-4">Players</h2>
                {ts.players.map((p) => (
                  <div>
                    <Chip key={p.name} label={p.name} />
                  </div>
                ))}
              </div>

              <div>
                <h2 className="text-lg font-bold mb-4">Tricks</h2>
                <div className="flex flex-col -space-y-6 items-center">
                  {groupedTricks.map((trickCards) => (
                    <div
                      className="flex -space-x-6"
                      key={trickCards[0].trickIndex}
                    >
                      {trickCards.map((tc) => (
                        <Card slug={tc.slug} size={Size.XS} key={tc.slug} />
                      ))}
                    </div>
                  ))}
                </div>
              </div>
              {ts.talonCards && (
                <div>
                  <h2 className="text-lg font-bold mb-4">Talon</h2>
                  <div className="flex -space-x-6">
                    {ts.talonCards.map((slug) => (
                      <Card slug={slug} size={Size.XS} key={slug} />
                    ))}
                  </div>
                </div>
              )}
              {ts.putdownCards && (
                <div>
                  <h2 className="text-lg font-bold mb-4">Putdowns</h2>
                  <div className="flex -space-x-6">
                    {ts.putdownCards.map((slug) => (
                      <Card slug={slug} size={Size.XS} key={slug} />
                    ))}
                  </div>
                </div>
              )}
              <div>
                <h2 className="text-lg font-bold mb-4">Card points</h2>
                <div>{ts.cardPoints}</div>
              </div>
              <div>{ts.winner ? "Winner!" : "Loser!"}</div>
            </div>
          );
        })}
      </div>
      <div>
        <Button onClick={gotoNextGame}>Next hand</Button>
      </div>
    </div>
  );
};
