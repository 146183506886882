import { matchesPath } from "../firestorePaths";
import { useFirestoreConnect } from "react-redux-firebase";

export const useConnectMatchListings = () => {
  const listeners = [
    {
      collection: matchesPath,
      storeAs: "matches",
    },
    {
      collectionGroup: "matchPlayers",
      storeAs: "matchPlayers",
    },
    {
      collectionGroup: "games",
      storeAs: "games",
    },
  ];

  useFirestoreConnect(listeners);
};
