import { CardSlug, Suit } from "../types/types";

export const parseCardSlug = (
  slug: CardSlug
): { suit: Suit; value: number } => {
  const [suit, stringValue] = slug.split("_");
  const value = parseInt(stringValue);
  if (isNaN(value)) {
    throw new Error(`Failed to parse card slug ${slug}`);
  }
  return { suit, value };
};
