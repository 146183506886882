import React from "react";
import classNames from "classnames";
import Face2OutlinedIcon from "@mui/icons-material/Face2Outlined";
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";

interface Props {
  name: string;
  human: boolean;
  className?: string;
}

export const PlayerNameIcon: React.FC<Props> = ({ name, human, className }) => {
  return (
    <div className={classNames(className, "flex space-x-1 items-center")}>
      {human ? <Face2OutlinedIcon /> : <SmartToyOutlinedIcon />}
      <div>{name}</div>
    </div>
  );
};
