import React from "react";
import { Avatar, Tooltip } from "@mui/material";
import { first } from "lodash";

export const LetterIcon: React.FC<{
  color: string;
  title: string;
}> = ({ color, title }) => {
  return (
    <Tooltip title={title}>
      <Avatar sx={{ bgcolor: color }}>{first(title)}</Avatar>
    </Tooltip>
  );
};
