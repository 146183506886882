import { GamePlayer } from "../types/types";

interface Props {
  nextFromPlayerId: string;
  players: GamePlayer[];
}

export const getNextPlayer = ({
  nextFromPlayerId,
  players,
}: Props): GamePlayer => {
  const nextFromPlayer = players.find((p) => p.id === nextFromPlayerId);
  if (nextFromPlayer === undefined) {
    throw new Error("failed to find nextFromPlayer");
  }

  const nextPlayerPosition = (nextFromPlayer.gamePosition + 1) % 4;

  const nextPlayer = players.find((p) => p.gamePosition === nextPlayerPosition);
  if (nextPlayer === undefined) {
    throw new Error("failed to find nextPlayer");
  }

  return nextPlayer;
};
