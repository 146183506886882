import { Team, WonBidSlug } from "../types/types";
import { BidSlug, RuferOptionSlug } from "../constants";

const POINTS = {
  [BidSlug.Solo]: 2,
  [BidSlug.Dreier]: 3,
  [BidSlug.BesserRufer]: 1,
  [BidSlug.SoloDreier]: 6,
  [BidSlug.Piccolo]: 2,
  [BidSlug.Bettel]: 3,
  [RuferOptionSlug.CallKing]: 1,
  [RuferOptionSlug.Sechserdreier]: 3,
  [RuferOptionSlug.Trischaken]: 1,
};

// TODO: Announcements
export const getMatchPoints = (
  wonBid: WonBidSlug,
  team: Team,
  winner: boolean
): number => {
  const points = POINTS[wonBid];

  // TODO: Trischaken!
  // TODO: SescherDreier off double
  if (team.players.length === 1) {
    return winner ? points * 3 : -(points * 3);
  }

  return winner ? points : -points;
};

// export const getMatchPoints = (
//   wonBid: WonBidSlug,
//   winner: boolean,
//   team: Team
// ): number => {
//   switch (wonBid) {
//     case BidSlug.Solo:
//       if (winner) {
//         switch (team.players.length) {
//           case 1:
//             // I've won solo vs 3
//             return 6;
//           case 2:
//             // I've won standard solo vs 2
//             return 2;
//           case 3:
//             // I've won as defence of 3
//             return 2;
//           default:
//             throw new Error(`Team length of ${team.players.length} found`);
//         }
//       } else {
//         switch (team.players.length) {
//           case 1:
//             // I've lost solo vs 3
//             return -6;
//           case 2:
//             // I've lost standard solo vs 2
//             return -2;
//           case 3:
//             // I've lost as defence of 3
//             return -2;
//           default:
//             throw new Error(`Team length of ${team.players.length} found`);
//         }
//       }
//     case BidSlug.Piccolo:
//       if (winner) {
//         switch (team.players.length) {
//           // I've won
//           case 1:
//             return 6;
//           // I've won as defence
//           case 3:
//             return 2;
//           default:
//             throw new Error(
//               `Team length of ${team.players.length} found for Piccolo`
//             );
//         }
//       } else {
//         switch (team.players.length) {
//           // I've lost solo vs 3
//           case 1:
//             return -6;
//           // I've lost as defence
//           case 3:
//             return -2;
//           default:
//             throw new Error(`Team length of ${team.players.length} found`);
//         }
//       }
//     case BidSlug.BesserRufer:
//     case BidSlug.Dreier:
//     case BidSlug.Bettel:
//       if (winner) {
//         switch (team.players.length) {
//           // I've won
//           case 1:
//             return 9;
//           // I've won as defence
//           case 3:
//             return 3;
//           default:
//             throw new Error(
//               `Team length of ${team.players.length} found for Piccolo`
//             );
//         }
//       } else {
//         switch (team.players.length) {
//           // I've lost solo vs 3
//           case 1:
//             return -9;
//           // I've lost as defence
//           case 3:
//             return -3;
//           default:
//             throw new Error(`Team length of ${team.players.length} found`);
//         }
//       }
//     case BidSlug.SoloDreier:
//     case RuferOptionSlug.CallKing:
//     case RuferOptionSlug.Trischaken:
//     case RuferOptionSlug.Sechserdreier:
//       if (winner) {
//         switch (team.players.length) {
//           case 1:
//             // I've won rufer vs 3
//             return 3;
//           case 2:
//             // I've won standard rufer vs 2
//             return 1;
//           case 3:
//             // I've won as defence of 3
//             return 1;
//           default:
//             return 0;
//         }
//       } else {
//         switch (team.players.length) {
//           case 1:
//             // I've lost rufer vs 3
//             return -3;
//           case 2:
//             // I've lost standard rufer vs 2
//             return -1;
//           case 3:
//             // I've lost as defence of 3
//             return -1;
//           default:
//             return 0;
//         }
//       }
//   }
// };
