import React from "react";
import { useSelector } from "react-redux";
import {
  selectDeclarer,
  selectPartner,
  selectPartnerKnown,
  selectWonBidSlug,
} from "../../../store/selectors";
import { BIDS, RUFER_OPTIONS } from "../../../constants";
import { PartnerIcon } from "../shared/icons/PartnerIcon";
import { PlayerNameIcon } from "../../PlayerNameIcon";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

export const PartnerDetails: React.FC = () => {
  const wonBidSlug = useSelector(selectWonBidSlug);
  const declarer = useSelector(selectDeclarer);
  const isPartnerKnown = useSelector(selectPartnerKnown);
  const partner = useSelector(selectPartner);

  if (wonBidSlug === undefined || declarer === undefined) {
    return null;
  }

  const wonBidDetails = [...BIDS, ...RUFER_OPTIONS].find(
    (bd) => bd.slug === wonBidSlug
  );

  if (wonBidDetails === undefined || !wonBidDetails.pickKing) {
    return null;
  }

  if (!isPartnerKnown) {
    return (
      <div className="flex items-center gap-4">
        <PartnerIcon />
        <QuestionMarkIcon />
      </div>
    );
  }

  if (partner === undefined) {
    return (
      <div className="flex gap-4">
        <PartnerIcon />
        <span>None</span>
      </div>
    );
  }

  return (
    <div className="flex gap-4">
      <PartnerIcon />
      <PlayerNameIcon name={partner.name} human={partner.human} />
    </div>
  );
};
