import React from "react";
import { useSelector } from "react-redux";
import { selectPickedKing, selectWonBidSlug } from "../../../store/selectors";
import { BIDS, RUFER_OPTIONS, RuferOptionSlug } from "../../../constants";
import { LetterIcon } from "../shared/icons/LetterIcon";
import { orange } from "@mui/material/colors";
import { Card } from "../shared/Card";
import { Size } from "../../../types/types";

export const WonBidDetails: React.FC = () => {
  const wonBidSlug = useSelector(selectWonBidSlug);
  const pickedKingSlug = useSelector(selectPickedKing);

  if (wonBidSlug === undefined) {
    return null;
  }

  const wonBidDetails = [...BIDS, ...RUFER_OPTIONS].find(
    (bd) => bd.slug === wonBidSlug
  );

  if (wonBidDetails === undefined) {
    return null;
  }

  const bidName =
    wonBidSlug === RuferOptionSlug.CallKing ? "Rufer" : wonBidDetails.name;

  return (
    <div className="flex gap-4 items-center">
      <LetterIcon title="Contract" color={orange[500]} />
      <span>{bidName}</span>
      {pickedKingSlug !== undefined && (
        <Card size={Size.XXS} slug={pickedKingSlug} />
      )}
    </div>
  );
};
