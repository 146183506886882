import React, { useCallback, useEffect, useState } from "react";
import { Alert, Button, LinearProgress } from "@mui/material";

import { useTricks } from "../../../hooks/useTricks";
import { useIntervalWhen } from "rooks";

import { useCurrentUser } from "../../../hooks/useCurrentUser";

import { TrickCard } from "./tricks/TrickCard";
import { useSelector } from "react-redux";
import {
  selectMyUserId,
  selectViewingTrickIndex,
} from "../../../store/selectors";
import { sortBy } from "lodash";

const DELAY_BETWEEN_TRICKS = 1000;
const DELAY_UPDATE_INTERVAL = 100;

export const Tricks: React.FC = () => {
  // useBotTrickPlaying();
  const myUserId = useSelector(selectMyUserId);
  const [viewingPrevious, setViewingPrevious] = useState(false);
  const { activePlayer, playableTrickIndex, getTrickCards } = useTricks();

  const viewingTrickIndex = useSelector(selectViewingTrickIndex);
  const { setViewingTrickIndex } = useCurrentUser();
  // const positionedPlayerIds = usePositionedGamePlayerIds();

  const [
    [showPlayableTrickCountdownActive, showPlayableTrickIn],
    setShowPlayableTrickCountdown,
  ] = useState([false, 0]);

  useIntervalWhen(
    () => {
      setShowPlayableTrickCountdown([
        true,
        showPlayableTrickIn - DELAY_UPDATE_INTERVAL,
      ]);
    },
    DELAY_UPDATE_INTERVAL,
    showPlayableTrickCountdownActive,
    false // wait for first interval https://react-hooks.org/docs/useIntervalWhen
  );

  useEffect(() => {
    if (viewingTrickIndex !== playableTrickIndex && !viewingPrevious) {
      setShowPlayableTrickCountdown([true, DELAY_BETWEEN_TRICKS]);
    }
  }, [viewingTrickIndex, playableTrickIndex, viewingPrevious]);

  useEffect(() => {
    if (
      viewingTrickIndex === undefined ||
      (showPlayableTrickCountdownActive && showPlayableTrickIn < 0)
    ) {
      setViewingTrickIndex(playableTrickIndex ?? null);
      setShowPlayableTrickCountdown([false, 0]);
    }
  }, [
    setViewingTrickIndex,
    showPlayableTrickCountdownActive,
    viewingTrickIndex,
    showPlayableTrickIn,
    playableTrickIndex,
  ]);

  const viewPlayableTrick = useCallback(() => {
    setViewingPrevious(false);
    setViewingTrickIndex(playableTrickIndex ?? null);
    setShowPlayableTrickCountdown([false, 0]);
  }, [playableTrickIndex, setViewingTrickIndex]);

  const viewPreviousTrick = useCallback(() => {
    if (playableTrickIndex === undefined) {
      return;
    }

    setViewingPrevious(true);
    setViewingTrickIndex(playableTrickIndex - 1);
  }, [playableTrickIndex, setViewingTrickIndex]);

  if (viewingTrickIndex === undefined || activePlayer === undefined) {
    return <div>Loading tricks</div>;
  }

  // TODO viewing trick before ticking over
  const cards = getTrickCards(viewingTrickIndex);

  const positionedCards = sortBy(cards, (card) => card.cardIndex);

  return (
    <div className="flex flex-col gap-8 flex-1 items-start relative">
      <div className="self-center justify-self-center text-lg font-bold absolute absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 flex flex-col">
        {activePlayer.id === myUserId &&
          viewingTrickIndex === playableTrickIndex && (
            <Alert severity="info" variant="filled">
              Play a card
            </Alert>
          )}
        {viewingTrickIndex !== playableTrickIndex && (
          <Button onClick={viewPlayableTrick} variant="contained">
            Continue
          </Button>
        )}
        {showPlayableTrickCountdownActive && (
          <div className="w-48">
            <LinearProgress />
          </div>
        )}
        {viewingTrickIndex === playableTrickIndex &&
          cards.length === 0 &&
          playableTrickIndex !== undefined &&
          playableTrickIndex > 0 && (
            <Button className="-mt-8" onClick={viewPreviousTrick}>
              View previous trick
            </Button>
          )}
      </div>
      <div className="flex items-center justify-center flex-1 gap-8">
        <div className="grid grid-cols-4 gap-4">
          {positionedCards.map((c) => (
            <TrickCard card={c} key={c.slug} />
          ))}
        </div>
      </div>
    </div>
  );
};
