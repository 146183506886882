import { CardSlug, HandCard } from "../types/types";
import { parseCardSlug } from "./parseCardSlug";
import { TRUMP } from "../constants";

export const isValidPutdown = (card: CardSlug, hand: HandCard[]): boolean => {
  const nonKingNonTrumps = hand.filter((c) => {
    const { suit, value } = parseCardSlug(c.slug);

    return suit !== TRUMP && value < 8;
  });

  // can't put down kings and trumps unless no choice
  if (nonKingNonTrumps.length > 0) {
    return nonKingNonTrumps.map((c) => c.slug).includes(card);
  }

  const { value, suit } = parseCardSlug(card);

  // otherwise can put down low value trumps ( can never put down Kings)
  return suit === TRUMP && ![22, 21, 1].includes(value);
};
