import { nanoid } from "nanoid";
import {
  uniqueNamesGenerator,
  adjectives,
  colors,
  animals,
  names,
} from "unique-names-generator";
import { times, shuffle } from "lodash";

import { matchPath, matchPlayerPath } from "../firestorePaths";

import { SEAT_POSITIONS } from "../constants";
import {
  MatchPlayer,
  HumanPlayerCount,
  SeatPosition,
} from "../types/firestoreTypes";
import { useFirestore } from "react-redux-firebase";

interface ReturnType {
  createMatch: (arg: { humanPlayerCount: HumanPlayerCount }) => Promise<void>;
}

export const useMatches = (): ReturnType => {
  const firestore = useFirestore();

  const createMatch = async ({
    humanPlayerCount,
  }: {
    humanPlayerCount: HumanPlayerCount;
  }): Promise<void> => {
    let botNames = new Set<string>();

    while (botNames.size < 3) {
      botNames.add(uniqueNamesGenerator({ dictionaries: [names] }));
    }

    const randomisedSeats = shuffle(SEAT_POSITIONS);

    const name: string = uniqueNamesGenerator({
      dictionaries: [colors, adjectives, animals],
    }); // red_big_donkey
    const matchId = nanoid();
    times(4 - humanPlayerCount, (index: number) => {
      const playerId = nanoid();
      const player: MatchPlayer = {
        matchId,
        id: playerId,
        human: false,
        name: Array.from(botNames)[index],
        seat: randomisedSeats[index] as SeatPosition,
      };

      const path = matchPlayerPath({ matchId, playerId });
      firestore.set(path, player);
    });

    const path = matchPath({ matchId });
    await firestore.set(path, { humanPlayerCount, name, id: matchId });
  };

  return {
    createMatch,
  };
};
