import React from "react";
import { useSelector } from "react-redux";
import { selectDeclarer } from "../../../store/selectors";
import { PlayerNameIcon } from "../../PlayerNameIcon";
import { DeclarerIcon } from "../shared/icons/DeclarerIcon";

export const DeclarerDetails: React.FC = () => {
  const declarer = useSelector(selectDeclarer);

  if (declarer === undefined) {
    return null;
  }

  return (
    <div className="flex gap-4">
      <DeclarerIcon />
      <PlayerNameIcon name={declarer.name} human={declarer.human} />
    </div>
  );
};
