import {MyHandCard} from "../types/types";

import { parseCardSlug } from "./parseCardSlug";

export const sortHand = (hand: MyHandCard[]) => {
  return Array.from(hand).sort((c1, c2) => {
    const { suit: suit1, value: value1 } = parseCardSlug(c1.slug);
    const { suit: suit2, value: value2 } = parseCardSlug(c2.slug);
    if (suit1 < suit2) {
      return 1;
    }

    if (suit1 > suit2) {
      return -1;
    }

    return value2 - value1;
  });
};
