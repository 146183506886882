import { useCallback } from "react";
import { useSelector } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import { max } from "lodash";

import { parseCardSlug } from "../helpers/parseCardSlug";
import { getWinningCard } from "../helpers/getWinningCard";

import { TRUMP } from "../constants";
import { HandCard, TrickCard } from "../types/types";
import { GamePlayer, CardSlug } from "../types/firestoreTypes";
import {
  selectActiveTrickPlayer,
  selectActiveTrickPlayerHandCards,
  selectPlayableTrickIndex,
  selectCurrentGameTrickCards,
  selectTricksFinished,
} from "../store/selectors";
import { useUpdateCard } from "./useUpdateCard";

interface ReturnType {
  loaded: boolean;
  trickCards: TrickCard[] | undefined;
  finished: boolean;
  activePlayer: GamePlayer | undefined;
  playableTrickIndex: number | undefined;
  playCard: (args: { card: HandCard }) => Promise<void>;
  getTrickCards: (trickIndex: number) => TrickCard[];
  isCardValid: (card: CardSlug) => boolean;
  isWinningCard: (args: { card: CardSlug; trickIndex: number }) => boolean;
  activePlayerValidCards: HandCard[] | undefined;
}

export const useTricks = (): ReturnType => {
  const trickCards = useSelector(selectCurrentGameTrickCards);
  const playableTrickIndex = useSelector(selectPlayableTrickIndex);
  const activePlayer = useSelector(selectActiveTrickPlayer);
  const activePlayerCards = useSelector(selectActiveTrickPlayerHandCards);
  const finished = useSelector(selectTricksFinished);
  const updateCard = useUpdateCard();

  // useEffect(() => {
  //   if (finished) {
  //     updateGame({ stage: GameStage.Finished });
  //   }
  // }, [finished, updateGame]);

  const playCard = useCallback(
    async ({ card }: { card: HandCard }) => {
      if (playableTrickIndex === undefined || !isLoaded(trickCards)) {
        return Promise.reject("No playable trick; failed to play card");
      }

      if (card.playerId !== activePlayer?.id) {
        return Promise.reject(
          `Refusing to play card belonging to player ID ${card.playerId}; active player ID is ${activePlayer?.id}`
        );
      }

      const playedTrickCards = trickCards.filter(
        (t) => t.trickIndex === playableTrickIndex
      );

      const maxPlayedTrickCardIndex = max(
        playedTrickCards.map(({ cardIndex }) => cardIndex)
      );
      const cardIndex =
        maxPlayedTrickCardIndex === undefined ? 0 : maxPlayedTrickCardIndex + 1;

      await updateCard(card.id, {
        trickIndex: playableTrickIndex,
        cardIndex,
      });
    },

    [playableTrickIndex, trickCards, activePlayer, updateCard]
  );

  const getTrickCardsForTrick = useCallback(
    (trickIndex: number) => {
      return trickCards?.filter((t) => t.trickIndex === trickIndex) ?? [];
    },
    [trickCards]
  );

  const isCardValid = useCallback(
    (card: CardSlug): boolean => {
      console.log("isCardValid?", { card });
      // finished
      if (playableTrickIndex === undefined || activePlayerCards === undefined) {
        return false;
      }

      const trickCardsForTrick = getTrickCardsForTrick(playableTrickIndex);
      const ledCard =
        trickCardsForTrick === undefined
          ? undefined
          : trickCardsForTrick.find((tc) => tc.cardIndex === 0);

      // lead
      // TODO: no leading pagat in Trischaken
      if (ledCard === undefined) {
        return true;
      }

      const { suit } = parseCardSlug(card);
      const { suit: ledSuit } = parseCardSlug(ledCard.slug);
      const parsedActivePlayerCards = activePlayerCards.map((c) =>
        parseCardSlug(c.slug)
      );

      const hasLedSuit = parsedActivePlayerCards.some(
        ({ suit }) => suit === ledSuit
      );

      const hasTrumps = parsedActivePlayerCards.some(
        ({ suit }) => suit === TRUMP
      );

      console.log("isCardValid?", { card, trickCardsForTrick, hasLedSuit });
      // TODO: play-up bids
      if (hasLedSuit) {
        return suit === ledSuit;
      }

      if (hasTrumps) {
        return suit === TRUMP;
      }

      // TODO: announced cards
      // TODO: Trischaken pagat only if last trump
      return true;
    },
    [playableTrickIndex, getTrickCardsForTrick, activePlayerCards]
  );

  const isWinningCard = useCallback(
    ({ card, trickIndex }: { card: CardSlug; trickIndex: number }): boolean => {
      const trickCards = getTrickCardsForTrick(trickIndex);
      if (trickCards?.find((tc) => tc.slug === card) === undefined) {
        throw new Error(`card ${card} is not in trick index ${trickIndex}`);
      }
      return getWinningCard(trickCards)?.slug === card;
    },
    [getTrickCardsForTrick]
  );

  return {
    loaded: isLoaded(trickCards),
    trickCards,
    finished,
    activePlayer,
    playCard,
    playableTrickIndex,
    getTrickCards: getTrickCardsForTrick,
    isCardValid,
    isWinningCard,
    activePlayerValidCards: activePlayerCards?.filter((c) =>
      isCardValid(c.slug)
    ),
  };
};
