import React from "react";
import { useFirebase, useFirestore } from "react-redux-firebase";

import { Home } from "./components/Home";
import { Login } from "./components/Login";
import { useUserId } from "./hooks/useUserId";
import { useConnectUsers } from "./hooks/useConnectUsers";
import "./App.css";

export const App: React.FC = () => {
  useConnectUsers();
  const firestore = useFirestore();
  const firebase = useFirebase();
  if (window.location.hostname === "localhost") {
    firestore.settings({
      host: "localhost:8080",
      ssl: false,
    });
    firebase.auth().useEmulator("http://localhost:8081");
  }

  return <AppWithFirestore />;
};

const AppWithFirestore: React.FC = () => {
  const { userId, logout } = useUserId();
  // if (!isLoaded(userId)) {
  //   return <div>loading userId</div>;
  // }
  //
  if (userId === null || userId === undefined) {
    return <Login />;
  }

  return <Home logout={logout} userId={userId} />;
};
