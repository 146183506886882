import React from "react";

export const HiddenCard: React.FC = (): React.ReactElement => {
  const fileName = "back.jpg";

  return (
    <div className="">
      {/* https://stackoverflow.com/questions/45334874/load-images-based-on-dynamic-path-in-reactjs */}
      <img
        src={process.env.PUBLIC_URL + "/cards/" + fileName}
        alt="card back"
        className="w-12 h-auto rounded border-solid border-black border-2"
      />
    </div>
  );
};
