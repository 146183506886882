import React from "react";
import { useSelector } from "react-redux";
import { selectGameStage } from "../../store/selectors";
import { GameStage } from "../../types/types";
import { BidsLog } from "./GameDetails/BidsLog";
import { DeclarerDetails } from "./GameDetails/DeclarerDetails";
import { HandDetails } from "./GameDetails/HandDetails";
import { PartnerDetails } from "./GameDetails/PartnerDetails";
import { WonBidDetails } from "./GameDetails/WonBidDetails";
import { AnnouncementDetails } from "./GameDetails/AnnouncementDetails";

export const GameDetails: React.FC = () => {
  const stage = useSelector(selectGameStage);
  return (
    <div className="p-4 border-dashed border-green-700 bg-gray-50 flex-1 flex flex-col gap-4">
      <HandDetails />
      <WonBidDetails />
      <DeclarerDetails />
      <PartnerDetails />
      <AnnouncementDetails />
      {stage === GameStage.Bidding && <BidsLog />}
    </div>
  );
};
