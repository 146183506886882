import { Match, Game } from "../types/firestoreTypes";
import { useSelector } from "react-redux";
import {
  selectCurrentMatch,
  selectCurrentMatchGames,
  selectCurrentMatchId,
  selectCurrentMatchLatestGame,
  selectCurrentMatchPlayers,
  selectMe,
} from "../store/selectors";
import { useFirestore } from "react-redux-firebase";
import { createGame } from "../helpers/createGame";
import { GameStage } from "../constants";
import { useAsyncEffect } from "@react-hook/async";

interface ReturnType {
  match: Match | undefined;
  games: Game[];
  joined: boolean;
  openGame: Game | undefined;
}

export const useCurrentMatch = (): ReturnType => {
  const firestore = useFirestore();
  const matchId = useSelector(selectCurrentMatchId);
  const match = useSelector(selectCurrentMatch);
  const games = useSelector(selectCurrentMatchGames);
  const matchPlayers = useSelector(selectCurrentMatchPlayers);
  const me = useSelector(selectMe);
  const latestGame = useSelector(selectCurrentMatchLatestGame);

  useAsyncEffect(async () => {
    if (
      matchId === undefined ||
      matchPlayers === undefined ||
      matchPlayers.length < 4 ||
      (latestGame !== undefined && latestGame?.stage !== GameStage.Finished)
    ) {
      return;
    }

    await createGame({
      matchId,
      gameIndex: latestGame ? latestGame.gameIndex + 1 : 0,
      players: matchPlayers,
      firestore,
    });
  }, [firestore, matchId, matchPlayers, latestGame]);

  const joined = matchPlayers?.find((p) => p.id === me?.id) !== undefined;

  return {
    match,
    games: games ?? [],
    joined,
    openGame: games?.find((g) => g.stage !== GameStage.Finished),
  };
};
