import React, { useEffect } from "react";

import { Game } from "./Game";
import { useCurrentUser } from "../hooks/useCurrentUser";
import { useCurrentMatch } from "../hooks/useCurrentMatch";
import { useSelector } from "react-redux";
import {
  selectCurrentGameId,
  selectCurrentMatchId,
  selectViewingScores,
} from "../store/selectors";
import { GameStage } from "../constants";
import { MatchScoreBoard } from "./MatchScoreBoard";
import { useConnectMatch } from "../hooks/useConnectMatch";

export const ConnectMatch: React.FC<{ matchId: string }> = ({
  matchId,
  children,
}) => {
  useConnectMatch({ matchId });
  return <>{children}</>;
};

export const ConnectedMatch: React.FC = () => {
  const { setCurrentGameId } = useCurrentUser();
  const { openGame } = useCurrentMatch();
  const currentGameId = useSelector(selectCurrentGameId);
  const viewingScores = useSelector(selectViewingScores);
  // Join first game when it's ready

  useEffect(() => {
    if (
      openGame !== undefined &&
      openGame.stage !== GameStage.Dealing &&
      currentGameId === undefined
    ) {
      setCurrentGameId(openGame.id);
    }
  }, [setCurrentGameId, currentGameId, openGame]);

  if (currentGameId !== undefined) {
    return viewingScores ? <MatchScoreBoard /> : <Game />;
  }

  if (openGame !== undefined) {
    return <div>Dealing...</div>;
  }

  return <div>Waiting for players</div>;
};

export const Match: React.FC = () => {
  const matchId = useSelector(selectCurrentMatchId);

  console.log("Match", { matchId });
  if (matchId === undefined) {
    return <div>loading matchId</div>;
  }

  return (
    <ConnectMatch matchId={matchId}>
      <ConnectedMatch />
    </ConnectMatch>
  );
};
