import React, { useCallback } from "react";
import { Badge } from "@mui/material";

import { useTricks } from "../../../../hooks/useTricks";

import { Card } from "../../shared/Card";

import { TrickCard as TrickCardType, Size } from "../../../../types/types";
import { PlayerNameIcon } from "../../../PlayerNameIcon";
import { useGamePlayer } from "../../../../hooks/useGamePlayer";

interface Props {
  card: TrickCardType;
  size?: Size;
}

export const TrickCard: React.FC<Props> = ({ card, size = Size.M }) => {
  const { slug, trickIndex } = card;
  const { isWinningCard } = useTricks();
  const { name, human } = useGamePlayer({ playerId: card.playerId });
  const WithWinningBadge = useCallback(
    ({ children }) => {
      const winning = isWinningCard({ trickIndex, card: card.slug });
      if (winning) {
        return (
          <Badge
            badgeContent="Winning"
            color="success"
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {children}
          </Badge>
        );
      }

      return children;
    },
    [isWinningCard, trickIndex, card.slug]
  );

  // const WithLedBadge = useCallback(
  //   ({ children }) => {
  //     const led = cardIndex === 0;
  //     if (led) {
  //       return (
  //         <Badge badgeContent="Led" color="primary">
  //           {children}
  //         </Badge>
  //       );
  //     }
  //
  //     return children;
  //   },
  //   [cardIndex]
  // );

  return (
    <div className="flex flex-col items-center">
      <WithWinningBadge>
        {/*<WithLedBadge>*/}
        <Card slug={slug} size={size} />
        {/*</WithLedBadge>*/}
      </WithWinningBadge>
      {name !== undefined && human !== undefined && (
        <PlayerNameIcon
          name={name}
          human={human}
          className="text-lg font-bold mt-2"
        />
      )}
    </div>
  );
};
