import { cardPath } from "../firestorePaths";
import { useFirestore } from "react-redux-firebase";
import { Card } from "../types/firestoreTypes";
import { selectCurrentGameId, selectCurrentMatchId } from "../store/selectors";
import { useSelector } from "react-redux";

type ReturnType = (cardId: string, update: Partial<Card>) => Promise<void>;

export const useUpdateCard = (): ReturnType => {
  const firestore = useFirestore();
  const gameId = useSelector(selectCurrentGameId);
  const matchId = useSelector(selectCurrentMatchId);
  const updateCard = async (cardId: string, update: Partial<Card>) => {
    if (matchId === undefined || gameId === undefined) {
      throw new Error("Failed to update card, matchId or gameId undefined");
    }
    const path = cardPath({ matchId, gameId, cardId });
    await firestore.update<Card>(path, update);
  };

  return updateCard;
};
