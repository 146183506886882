import {
  ReduxFirestoreQuerySetting,
  useFirestoreConnect,
} from "react-redux-firebase";

interface Props {
  matchId: string;
}

/* @todo this gets called too often on the listing screen */
export const useConnectMatchListing = ({ matchId }: Props) => {
  // Need to explicitly type here - seems to be to do with fixed length of 'where' array
  const listeners: ReduxFirestoreQuerySetting[] = [
    {
      collectionGroup: "matchPlayers",
      where: ["matchId", "==", matchId],
      storeAs: "matchListingsPlayers",
    },
    {
      collectionGroup: "games",
      where: ["matchId", "==", matchId],
      storeAs: "MatchListingsGames",
    },
  ];

  console.log("useConnectMatch", { matchId, listeners });
  useFirestoreConnect(listeners);
};
