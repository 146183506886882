import React, { useState } from "react";
import { Alert, TextField } from "@mui/material";

import { useUserId } from "../hooks/useUserId";
import { Button } from "@mui/material";

export const Login: React.FC = () => {
  console.log("Login");
  const { login, loginError, loginErrorMsg } = useUserId();
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");

  return (
    <div className="flex flex-col w-screen h-screen p-8">
      <h1 className="text-3xl font-bold mb-10">Königsrufen</h1>
      <div className="flex flex-col items-start mb-8 gap-2">
        <TextField
          // html input attribute
          name="name"
          type="text"
          placeholder="Kat"
          // pass down to FormLabel as children
          label="Name"
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
        <TextField
          name="password"
          type="password"
          placeholder="password"
          label="Password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
        {loginError && <Alert severity="error">{loginErrorMsg}</Alert>}
        <Button variant="outlined" onClick={() => login(name, password)}>
          Login / Create account
        </Button>
      </div>
    </div>
  );
};
