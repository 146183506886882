import {
  Announcement,
  AnnouncementOutcome,
  Card,
  CumulativeGameOutcome,
  FirestoreOrderedStoreState,
  Game,
  GameOutcome,
  GamePlayer,
  GameStage,
  HandCard,
  StoreState,
  Team,
  TeamScore,
  TrickCard,
  WonBidSlug,
} from "../types/types";
import { createSelector } from "@reduxjs/toolkit";
import { isLoaded } from "react-redux-firebase";
import { gamePath } from "../firestorePaths";
import { compact, flatten, max, maxBy, orderBy, sum, uniqBy } from "lodash";
import { getWinningCard } from "../helpers/getWinningCard";
import { getNextPlayer } from "../helpers/getNextPlayer";
import { Match } from "../types/firestoreTypes";
import { enrichMadeBid } from "../helpers/enrichMadeBid";
import {
  AnnouncementSlug,
  BASE_ANNOUNCEMENTS,
  BidSlug,
  KONTRA_GAME_ANNOUNCEMENT,
  KontraSlug,
  RuferOptionSlug,
} from "../constants";
import { getWonCards } from "../helpers/getWonCards";
import { getCardPoints } from "../helpers/getCardPoints";
import { getMatchPoints } from "../helpers/getMatchPoints";

export const selectMyUserId = (state: StoreState) =>
  state.currentUserId ?? undefined;

// export const selectFirestoreState = (state: StoreState) => state.firestore.data;
export const selectFirestoreOrderedState = (state: StoreState) =>
  state.firestore.ordered;
export const selectUsers = (state: StoreState) => state.firestore.ordered.users;

export const selectMe = createSelector(
  selectUsers,
  selectMyUserId,
  (users, userId) => {
    if (users === undefined || userId === undefined) {
      return undefined;
    }

    const user = users.find((u) => u.id === userId);
    if (user === undefined) {
      return undefined;
    }
    return { ...user, id: userId };
  }
);

export const selectCurrentMatchId = createSelector(selectMe, (currentUser) => {
  return currentUser?.currentMatchId ?? undefined;
});
export const selectCurrentGameId = createSelector(
  selectMe,
  (currentUser) => currentUser?.currentGameId ?? undefined
);

const selectGames = createSelector(
  selectFirestoreOrderedState,
  ({ games }) => games
);

const getMatchGames = (
  state: FirestoreOrderedStoreState,
  matchId: string
): Game[] | undefined => state.games?.filter((g) => g.matchId === matchId);

export const selectMatchGames = (matchId: string) =>
  createSelector(selectGames, (games) =>
    games?.filter((g) => g.matchId === matchId)
  );

export const selectCurrentMatchGames = createSelector(
  selectGames,
  selectCurrentMatchId,
  (games, matchId) => {
    if (matchId === undefined) {
      return undefined;
    }
    return games?.filter((g) => g.matchId === matchId);
  }
);

export const selectMatches = createSelector(
  selectFirestoreOrderedState,
  ({ matches }) => matches
);

export const selectCurrentMatch = createSelector(
  selectMatches,
  selectCurrentMatchId,
  (matches, currentMatchId): Match | undefined =>
    matches?.find((m) => m.id === currentMatchId)
);

export const selectCurrentGame = createSelector(
  selectCurrentGameId,
  selectCurrentMatchGames,
  (currentGameId, games) => {
    if (currentGameId === undefined || games === undefined) {
      return undefined;
    }

    return games.find((g) => g.id === currentGameId);
  }
);

const getGame = (
  state: FirestoreOrderedStoreState,
  matchId: string,
  gameId: string
): Game | undefined => {
  const games = getMatchGames(state, matchId);

  if (games === undefined) {
    return undefined;
  }

  return games.find((g) => g.id === gameId);
};

const getBids = (
  state: FirestoreOrderedStoreState,
  matchId: string | undefined,
  gameId: string | undefined
) => {
  if (state.bids === undefined) {
    return undefined;
  }
  return state.bids.filter((b) => b.matchId === matchId && b.gameId === gameId);
};

export const selectCurrentGameBids = createSelector(
  selectFirestoreOrderedState,
  selectCurrentMatchId,
  selectCurrentGameId,
  getBids
);

export const selectAnnouncements = createSelector(
  selectFirestoreOrderedState,
  selectCurrentMatchId,
  selectCurrentGameId,
  (state, matchId, gameId) =>
    state.announcements?.filter(
      (a) => a.matchId === matchId && a.gameId === gameId
    )
);

export const selectMatch = (matchId: string) =>
  createSelector(selectMatches, (matches) =>
    matches?.find((m) => m.id === matchId)
  );

export const selectCurrentMatchLatestGame = createSelector(
  selectCurrentMatchGames,
  (games) => maxBy(games, "gameIndex")
);

const selectAllMatchPlayers = createSelector(
  selectFirestoreOrderedState,
  ({ matchPlayers }) => matchPlayers
);

export const selectPlayersForMatch = (matchId: string) =>
  createSelector(selectAllMatchPlayers, (matchPlayers) =>
    matchPlayers?.filter((mp) => mp.matchId === matchId)
  );

export const selectCurrentMatchPlayers = createSelector(
  selectAllMatchPlayers,
  selectCurrentMatchId,
  (matchPlayers, currentMatchId) => {
    if (currentMatchId === undefined) {
      return undefined;
    }

    return matchPlayers?.filter((mp) => mp.matchId === currentMatchId);
  }
);

const getGamePlayers = (
  state: FirestoreOrderedStoreState,
  matchId: string | undefined,
  gameId: string | undefined
) =>
  (state.gamePlayers ?? []).filter(
    (gp) => gp.matchId === matchId && gp.gameId === gameId
  );

const selectAllGamePlayers = createSelector(
  selectFirestoreOrderedState,
  ({ gamePlayers }) => gamePlayers
);

export const selectCurrentGamePlayers = createSelector(
  selectFirestoreOrderedState,
  selectCurrentMatchId,
  selectCurrentGameId,
  getGamePlayers
);

export const selectCurrentGamePath = createSelector(
  selectCurrentMatchId,
  selectCurrentGameId,
  (currentMatchId, currentGameId) => {
    if (currentMatchId === undefined || currentGameId === undefined) {
      return undefined;
    }
    return gamePath({ matchId: currentMatchId, gameId: currentGameId });
  }
);

export const selectGamePlayer = (playerId: string | undefined) =>
  createSelector(selectCurrentGamePlayers, (players) => {
    if (playerId === undefined || players === undefined) {
      return undefined;
    }
    return players?.find((p) => p.id === playerId);
  });

const getCards = (
  state: FirestoreOrderedStoreState,
  matchId: string | undefined,
  gameId: string | undefined
) => state.cards?.filter((c) => c.matchId === matchId && c.gameId === gameId);

const selectCards = createSelector(
  selectFirestoreOrderedState,
  ({ cards }) => cards
);

export const selectCurrentGameCards = createSelector(
  selectCards,
  selectCurrentMatchId,
  selectCurrentGameId,
  (cards, matchId, gameId) =>
    cards?.filter((c) => c.matchId === matchId && c.gameId === gameId)
);

export const selectCurrentGameTrickCards = createSelector(
  selectCurrentGameCards,
  (cards) => cards?.filter((c): c is TrickCard => c.trickIndex !== undefined)
);

const selectLastPlayedTrickIndex = createSelector(
  selectCurrentGameTrickCards,
  (cards) =>
    cards === undefined
      ? undefined
      : max(cards.map(({ trickIndex }) => trickIndex))
);

const selectLastPlayedTrickCards = createSelector(
  selectCurrentGameTrickCards,
  selectLastPlayedTrickIndex,
  (trickCards, lastPlayedTrickIndex) =>
    trickCards?.filter((tc) => tc.trickIndex === lastPlayedTrickIndex)
);

const selectLastPlayedTrickCardCount = createSelector(
  selectLastPlayedTrickCards,
  (trickCards) => trickCards?.length ?? 0
);

export const selectPlayableTrickIndex = createSelector(
  selectCurrentGameTrickCards,
  selectLastPlayedTrickIndex,
  selectLastPlayedTrickCardCount,
  (trickCards, lastPlayedTrickIndex, lastPlayedTrickCardCount) => {
    if (trickCards === undefined) {
      return undefined;
    }

    if (lastPlayedTrickIndex === undefined) {
      return 0;
    }

    if (lastPlayedTrickCardCount < 4) {
      return lastPlayedTrickIndex;
    }

    // finished
    if (lastPlayedTrickIndex === 11) {
      return undefined;
    }

    return lastPlayedTrickIndex + 1;
  }
);

export const selectForehand = createSelector(
  selectCurrentGamePlayers,
  (players) => players?.find((p) => p.gamePosition === 0)
);

export const selectPickedKing = createSelector(
  selectCurrentGame,
  (game) => game?.pickedKing
);

export const selectPartnerId = createSelector(
  selectPickedKing,
  selectCurrentGameCards,
  (king, cards) => {
    if (king === undefined || cards === undefined) {
      return undefined;
    }
    return cards.find((c) => c.slug === king)?.playerId;
  }
);

export const selectPartner = createSelector(
  selectPartnerId,
  selectCurrentGamePlayers,
  (partnerId, players) => {
    if (partnerId === undefined || players === undefined) {
      return undefined;
    }

    return players.find((p) => p.id === partnerId);
  }
);

export const selectTricksFinished = createSelector(
  selectCurrentGameTrickCards,
  (trickCards) => trickCards?.length === 12 * 4
);

export const selectActiveTrickPlayer = createSelector(
  selectCurrentGamePlayers,
  selectCurrentGameTrickCards,
  selectPlayableTrickIndex,
  selectForehand,
  (players, trickCards, playableTrickIndex, forehand) => {
    if (
      players === undefined ||
      !isLoaded(trickCards) ||
      playableTrickIndex === undefined
    ) {
      return undefined;
    }

    const playableTrickCards = trickCards.filter(
      (tc) => tc.trickIndex === playableTrickIndex
    );

    if (playableTrickCards.length === 0) {
      // TODO: vary for bids
      if (playableTrickIndex === 0) {
        return forehand;
      }
      const previousTrickCards = trickCards.filter(
        (tc) => tc.trickIndex === playableTrickIndex - 1
      );
      return players.find(
        (p) => p.id === getWinningCard(previousTrickCards).playerId
      );
    }

    const lastPlayedCard = maxBy(
      playableTrickCards,
      ({ cardIndex }) => cardIndex
    );

    if (lastPlayedCard === undefined) {
      throw new Error("failed to find last played card for trick with cards");
    }

    return getNextPlayer({
      nextFromPlayerId: lastPlayedCard.playerId,
      players,
    });
  }
);

export const selectCurrentGameHandCards = createSelector(
  selectCurrentGameCards,
  (cards) =>
    cards?.filter(
      (c): c is HandCard =>
        c.trickIndex === undefined && !c.isPutdown && c.playerId !== undefined
    )
);

export const selectActiveTrickPlayerHandCards = createSelector(
  selectActiveTrickPlayer,
  selectCurrentGameHandCards,
  (activeTrickPlayer, cards) => {
    if (activeTrickPlayer === undefined || cards === undefined) {
      return undefined;
    }

    return cards.filter((c) => c.playerId === activeTrickPlayer.id);
  }
);

export const selectLastBid = createSelector(selectCurrentGameBids, (bids) => {
  if (bids === undefined) {
    return undefined;
  }

  return maxBy(bids, ({ index }) => index);
});

export const selectLastAnnouncement = createSelector(
  selectAnnouncements,
  (anns) => {
    if (anns === undefined) {
      return undefined;
    }

    return maxBy(anns, ({ index }) => index);
  }
);

export const selectPlayerBids = (playerId: string | undefined) =>
  createSelector(selectCurrentGameBids, (bids) => {
    if (playerId === undefined) {
      return undefined;
    }
    return bids
      ?.filter((b) => b.playerId === playerId)
      .map((b) => enrichMadeBid(b));
  });

export const selectPlayerAnnouncements = (playerId: string | undefined) =>
  createSelector(selectAnnouncements, (announcements) => {
    if (playerId === undefined || announcements === undefined) {
      return undefined;
    }
    return announcements.filter((a) => a.playerId === playerId);
  });

export const selectNonPassMadeBids = createSelector(
  selectCurrentGameBids,
  (bids) => {
    if (bids === undefined) {
      return undefined;
    }
    return bids.filter((b) => b.slug !== BidSlug.Pass);
  }
);

export const selectWinningBid = createSelector(
  selectNonPassMadeBids,
  (bids) => {
    if (bids === undefined) {
      return undefined;
    }
    const winningBid = maxBy(bids, ({ index }) => index);
    if (winningBid === undefined || winningBid.slug === BidSlug.Pass) {
      return undefined;
    }
    return enrichMadeBid(winningBid);
  }
);

const getDeclarer = (
  players: GamePlayer[] | undefined,
  declarerId: string | undefined
) => {
  if (players === undefined || declarerId === undefined) {
    return undefined;
  }
  return players.find((p) => p.id === declarerId);
};

export const selectPlayerHandCards = (playerId: string | undefined) =>
  createSelector(selectCurrentGameHandCards, (cards) => {
    if (cards === undefined || playerId === undefined) {
      return undefined;
    }
    return cards.filter((c) => c.playerId === playerId);
  });

export const selectCurrentGamePutdowns = createSelector(
  selectCurrentGameCards,
  (cards) => cards?.filter((c) => c.isPutdown)
);

export const selectCurrentGameTalon = createSelector(
  selectCurrentGameCards,
  (cards) =>
    cards?.filter((c) => !c.isPutdown && !c.playerId && !c.trickIndex) ?? []
);

export const selectRuferOption = createSelector(
  selectCurrentGame,
  (game) => game?.ruferOption
);

export const selectBiddingFinished = createSelector(
  selectCurrentGameBids,
  (madeBids) => {
    if (!isLoaded(madeBids)) {
      return false;
    }

    if (madeBids.length < 4) {
      return false;
    }

    return orderBy(madeBids, ["index"])
      .slice(-3)
      .every((b) => b.slug === BidSlug.Pass);
  }
);

export const selectMyHandCards = createSelector(
  selectCurrentGameCards,
  selectMyUserId,
  (cards, myUserId) => {
    if (cards === undefined || myUserId === undefined) {
      return undefined;
    }
    return cards.filter(
      (c): c is HandCard =>
        c.playerId === myUserId && c.trickIndex === undefined && !c.isPutdown
    );
  }
);

export const selectGameStage = createSelector(
  selectCurrentGame,
  (game) => game?.stage
);

export const selectViewingTrickIndex = createSelector(
  selectMe,
  (currentUser) => currentUser?.viewingTrickIndex
);

export const selectViewingScores = createSelector(
  selectMe,
  (currentUser) => currentUser?.viewingScores
);

export const selectWonBidSlug = createSelector(
  selectWinningBid,
  selectRuferOption,
  selectGameStage,
  (winningBid, ruferOption, gameStage): WonBidSlug | undefined => {
    if (
      gameStage === GameStage.Bidding ||
      gameStage === GameStage.RuferOptions
    ) {
      return undefined;
    }

    // TODO: should be able to get rid of this type casting
    return ruferOption ?? (winningBid?.slug as WonBidSlug);
  }
);

export const selectTalonExchangeCount = createSelector(
  selectWonBidSlug,
  (wonBidSlug) => {
    switch (wonBidSlug) {
      case undefined:
      case BidSlug.Piccolo:
      case BidSlug.Bettel:
      case RuferOptionSlug.Trischaken: {
        return undefined;
      }
      case BidSlug.Solo:
      case BidSlug.BesserRufer:
      case BidSlug.Dreier:
      case RuferOptionSlug.CallKing:
      case BidSlug.SoloDreier: {
        return 3;
      }
      case RuferOptionSlug.Sechserdreier: {
        return 6;
      }
    }
  }
);

export const selectDeclarerId = createSelector(
  selectWinningBid,
  selectGameStage,
  (bid, gameStage) => {
    if (gameStage === GameStage.Bidding) {
      return undefined;
    }
    return bid?.playerId;
  }
);

export const selectDeclarer = createSelector(
  selectCurrentGamePlayers,
  selectDeclarerId,
  getDeclarer
);

export const selectIAmDeclarer = createSelector(
  selectDeclarerId,
  selectMyUserId,
  (declarerId, myId) => declarerId === myId
);

export const selectDeclarerHandCards = createSelector(
  selectCurrentGameHandCards,
  selectDeclarerId,
  (handCards, declarerId) => handCards?.filter((c) => c.playerId === declarerId)
);

export const selectPickedKingCard = createSelector(
  selectPickedKing,
  selectCurrentGameCards,
  (pickedKingSlug, cards) => {
    return cards?.find((c) => c.slug === pickedKingSlug);
  }
);

export const selectPartnerKnown = createSelector(
  selectMe,
  selectPartner,
  selectPickedKingCard,
  (currentUser, partner, pickedKingCard) => {
    if (
      currentUser === undefined ||
      partner === undefined ||
      pickedKingCard === undefined
    ) {
      return false;
    }

    if (partner.id === currentUser.id) {
      return true;
    }

    if (pickedKingCard.trickIndex !== undefined) {
      return true;
    }

    // TODO seen in Talon, but not picked up

    return false;
  }
);

export const selectActiveAnnouncer = createSelector(
  selectLastAnnouncement,
  selectDeclarer,
  selectCurrentGamePlayers,
  selectGameStage,
  (lastAnnouncement, declarer, players, gameStage) => {
    if (players === undefined || gameStage !== GameStage.Announcements) {
      return undefined;
    }

    if (lastAnnouncement === undefined) {
      return declarer;
    }

    if (lastAnnouncement.slug === AnnouncementSlug.Pass) {
      return getNextPlayer({
        nextFromPlayerId: lastAnnouncement.playerId,
        players,
      });
    }

    return players.find((p) => p.id === lastAnnouncement.playerId);
  }
);

const getTeams = (
  declarer: GamePlayer | undefined,
  partner: GamePlayer | undefined,
  players: GamePlayer[] | undefined
): Team[] | undefined => {
  if (declarer === undefined || players === undefined) {
    return undefined;
  }

  const declarerTeam = {
    players: uniqBy(compact([declarer, partner]), (p) => p.id),
    declarers: true,
  };

  // TODO Trischaken
  const defenderTeam = {
    players: players.filter(
      (p) => !declarerTeam.players.map((p) => p.id).includes(p.id)
    ),
    declarers: false,
  };

  return [declarerTeam, defenderTeam];
};

export const selectCurrentGameTeams = createSelector(
  selectDeclarer,
  selectPartner,
  selectCurrentGamePlayers,
  getTeams
);

export const selectValidAnnouncements = createSelector(
  selectAnnouncements,
  selectActiveAnnouncer,
  selectCurrentGameTeams,
  (madeAnnouncements, activeAnnouncer, teams): Announcement[] | undefined => {
    if (
      activeAnnouncer === undefined ||
      teams === undefined ||
      teams.length !== 2
    ) {
      return undefined;
    }

    if (madeAnnouncements === undefined || madeAnnouncements.length === 0) {
      // First announcer is always declarer so we don't need to check team here
      return BASE_ANNOUNCEMENTS;
    }

    const activeAnnouncerTeam = teams.find((t) =>
      t.players.map((p) => p.id).includes(activeAnnouncer.id)
    );

    if (activeAnnouncerTeam === undefined) {
      throw new Error("no team found for announcer");
    }

    const teamPlayerIds = activeAnnouncerTeam.players.map((p) => p.id);

    const teamAnnouncements = madeAnnouncements.filter((a) =>
      teamPlayerIds.includes(a.playerId)
    );
    const oppositionAnnouncements = madeAnnouncements.filter(
      (a) => !teamPlayerIds.includes(a.playerId)
    );

    let validAnnouncements: Announcement[] = BASE_ANNOUNCEMENTS;

    if (!activeAnnouncerTeam.declarers) {
      validAnnouncements = [...validAnnouncements, KONTRA_GAME_ANNOUNCEMENT];
    }

    // can kontra oppositions announcements
    validAnnouncements = [
      ...validAnnouncements,
      ...oppositionAnnouncements
        .map((a): Announcement | undefined => {
          switch (a.slug) {
            case AnnouncementSlug.Pass:
            case KontraSlug.Subkontra:
              return undefined;
            case KontraSlug.Rekontra:
              return {
                slug: KontraSlug.Subkontra,
                base: a.base,
              };
            case KontraSlug.Kontra:
              return {
                slug: KontraSlug.Rekontra,
                base: a.base,
              };
            default:
              return {
                slug: KontraSlug.Kontra,
                base: a.slug,
              };
          }
        })
        .filter((a): a is Announcement => a !== undefined),
    ];

    // can't make an announcement your team's already made, except pass
    validAnnouncements = validAnnouncements.filter(
      (a) =>
        !teamAnnouncements.some(
          (ta) =>
            ta.slug !== AnnouncementSlug.Pass &&
            ta.slug === a.slug &&
            ta.base === a.base
        )
    );

    return validAnnouncements;
  }
);
export const selectIAmAnnouncer = createSelector(
  selectActiveAnnouncer,
  selectMyUserId,
  (announcer, myId) => announcer?.id === myId
);

export const selectGameIndex = createSelector(
  selectCurrentGame,
  (game) => game?.gameIndex
);

const getTeamScores = (
  teams: Team[] | undefined,
  trickCards: TrickCard[] | undefined,
  talon: Card[] | undefined,
  putdowns: Card[] | undefined,
  wonBid: WonBidSlug | undefined
): TeamScore[] | undefined => {
  if (teams === undefined || trickCards === undefined || wonBid === undefined) {
    return undefined;
  }
  return teams.map((team) => {
    const teamTrickCards = flatten(
      team.players.map((p) => getWonCards({ trickCards, playerId: p.id }))
    );
    // TODO: declarer gets talon vs 3 in Solo
    const talonCards = team.declarers
      ? undefined
      : talon?.map((card) => card.slug);
    const putdownCards = team.declarers
      ? putdowns?.map((card) => card.slug)
      : undefined;
    const allWonCards = [
      ...teamTrickCards.map((tc) => tc.slug),
      ...(talonCards ?? []),
      ...(putdownCards ?? []),
    ];
    const teamCardPoints = getCardPoints(allWonCards);

    // TODO: consider bid
    const winner = team.declarers ? teamCardPoints > 35 : teamCardPoints >= 35;

    const matchPoints = getMatchPoints(wonBid, team, winner);
    const teamScore: TeamScore = {
      ...team,
      trickCards: teamTrickCards,
      cardPoints: teamCardPoints,
      talonCards,
      putdownCards,
      matchPoints,
      winner,
    };

    return teamScore;
  });
};

export const selectCurrentGameTeamScores = createSelector(
  selectCurrentGameTeams,
  selectCurrentGameTrickCards,
  selectCurrentGameTalon,
  selectCurrentGamePutdowns,
  selectWonBidSlug,
  getTeamScores
);

const getGameOutcome = (
  orderedState: FirestoreOrderedStoreState,
  matchId: string,
  gameId: string
): GameOutcome | undefined => {
  const game = getGame(orderedState, matchId, gameId);
  const players = getGamePlayers(orderedState, matchId, gameId);
  const bids = getBids(orderedState, matchId, gameId);
  const cards = getCards(orderedState, matchId, gameId);
  const wonBid = maxBy(
    bids?.filter((b) => b.slug !== BidSlug.Pass),
    ({ index }) => index
  );
  const wonBidSlug =
    game?.ruferOption ?? (wonBid?.slug as WonBidSlug | undefined);

  const declarerId = wonBid?.playerId;
  const declarer = players?.find((p) => p.id === declarerId);
  const partnerId = cards?.find((c) => c.slug === game?.pickedKing)?.playerId;
  const partner = players?.find((p) => p.id === partnerId);
  const teams = getTeams(declarer, partner, players);
  const trickCards = cards?.filter(
    (c): c is TrickCard => c.trickIndex !== undefined
  );
  const talon = cards?.filter(
    (c) => !c.isPutdown && !c.playerId && !c.trickIndex
  );
  const putdowns = cards?.filter((c) => c.isPutdown);

  const teamScores = getTeamScores(
    teams,
    trickCards,
    talon,
    putdowns,
    wonBidSlug
  );
  if (
    game === undefined ||
    wonBidSlug === undefined ||
    teamScores === undefined
  ) {
    return undefined;
  }

  // const playerOutcomes: GamePlayerOutcome[] = [];
  // teamScores.forEach((ts) => {
  //   ts.players.forEach((p) => {
  //     playerOutcomes.push({
  //       ...p,
  //       score: ts.matchPoints,
  //       isDeclarer: p.id === declarerId,
  //     });
  //   });
  // });
  const announcements: AnnouncementOutcome[] = []; // TODO

  return {
    ...game,
    players: [],
    bid: { slug: wonBidSlug, made: true },
    announcements,
  };
};

const getGameOutcomes = (
  orderedState: FirestoreOrderedStoreState,
  matchId: string | undefined
): CumulativeGameOutcome[] | undefined => {
  if (matchId === undefined) {
    return undefined;
  }
  const games = getMatchGames(orderedState, matchId);
  const gameOutcomes = games
    ?.map((g) => getGameOutcome(orderedState, matchId, g.id))
    .filter((go): go is GameOutcome => go !== undefined);
  return gameOutcomes?.map((go) => {
    return {
      ...go,
      players: go.players.map((p) => {
        const cumulativeScore = sum(
          gameOutcomes
            .filter((go2) => go2.gameIndex <= go.gameIndex)
            .flatMap((go) => go.players)
            .filter((p2) => p2.id === p.id)
            .map((p) => p.score)
        );
        return { ...p, cumulativeScore };
      }),
    };
  });
};

export const selectCurrentMatchGameOutcomes = createSelector(
  selectFirestoreOrderedState,
  selectCurrentMatchId,
  getGameOutcomes
);

export const selectPlayerName = (playerId: string) =>
  createSelector(
    selectAllGamePlayers,
    (gamePlayers) => gamePlayers?.find((p) => p.id === playerId)?.name
  );

export const selectLocalPutdowns = (state: StoreState) => state.localPutdowns;
