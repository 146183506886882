import { Announcement } from "../types/types";
import { KontraSlug } from "../constants";

export function getAnnouncementName(announcement: Announcement) {
  switch (announcement.slug) {
    case KontraSlug.Kontra:
    case KontraSlug.Rekontra:
    case KontraSlug.Subkontra:
      return announcement.slug + " the " + announcement.base;
    default:
      return announcement.slug;
  }
}
