import { maxBy } from "lodash";

import { parseCardSlug } from "./parseCardSlug";
import { TrickCard } from "../types/types";

export const getWinningCard = (cards: TrickCard[]): TrickCard => {
  const ledCard = cards.find((c) => c.cardIndex === 0);
  if (ledCard === undefined) {
    throw new Error("no led card found");
  }

  const cardsWithParsedValues = cards.map((c) => ({
    card: c,
    ...parseCardSlug(c.slug),
  }));
  const trumps = cardsWithParsedValues.filter((c) => c.suit === "trump");
  const highestTrump = maxBy(trumps, (t) => t.value)?.card;
  if (highestTrump !== undefined) {
    return highestTrump;
  }

  const { suit: ledSuit } = parseCardSlug(ledCard.slug);

  const ledSuitCards = cardsWithParsedValues.filter((c) => c.suit === ledSuit);
  const highestLedSuitCard = maxBy(ledSuitCards, (t) => t.value)?.card;

  if (highestLedSuitCard === undefined) {
    throw new Error("highestLedSuitCard undefined...something went wrong");
  }

  return highestLedSuitCard;
};
