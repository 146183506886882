import { useFirebase } from "react-redux-firebase";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectMyUserId } from "../store/selectors";
import { clearCurrentUserId, setCurrentUserId } from "../store/actions";
import { FirebaseError } from "firebase/app";

interface ReturnType {
  userId: string | undefined;
  login: (name: string, password: string) => void;
  logout: () => void;
  loginError: boolean;
  loginErrorMsg: string;
}

export const useUserId = (): ReturnType => {
  const firebase = useFirebase();
  const dispatch = useDispatch();
  const currentUserId = useSelector(selectMyUserId);
  const [loginError, setLoginError] = useState(false);
  const [loginErrorMsg, setLoginErrorMsg] = useState("");
  firebase.auth().onAuthStateChanged((authUser) => {
    if (authUser) {
      dispatch(setCurrentUserId(authUser.uid));
    } else {
      dispatch(clearCurrentUserId);
    }
  });

  const login = async (name: string, password: string) => {
    console.log("login");
    setLoginError(false);
    setLoginErrorMsg("");
    const email = name + "@fake.com";
    try {
      await firebase.login({ email, password });
    } catch (e) {
      const { code } = e as FirebaseError;
      switch (code) {
        case "auth/wrong-password":
          setLoginError(true);
          setLoginErrorMsg(
            "That account exists, but that's not the password. Enter the correct password or a different name."
          );
          return;
      }

      try {
        await firebase.createUser({ email, password }, { name });
      } catch (e) {
        const { message } = e as FirebaseError;
        setLoginError(true);
        setLoginErrorMsg(message);
      }
    }
  };

  const logout = async () => {
    await firebase.logout();
  };

  return {
    userId: currentUserId,
    login,
    logout,
    loginError,
    loginErrorMsg,
  };
};
