import React from "react";

import { useCurrentUser } from "../hooks/useCurrentUser";

import { MatchPicker } from "./MatchPicker";
import { Match } from "./Match";
import { useSelector } from "react-redux";
import { selectCurrentMatchId, selectViewingScores } from "../store/selectors";
import { useConnectCurrentUser } from "../hooks/useConnectCurrentUser";
import { useConnectMatchListings } from "../hooks/useConnectMatchListings";

interface Props {
  userId: string;
  logout: () => void;
}

export const Home: React.FC<Props> = ({ userId, logout }) => {
  useConnectCurrentUser({ userId });
  useConnectMatchListings();
  const { clearSelectedMatch, setViewingScores } = useCurrentUser();
  const currentMatchId = useSelector(selectCurrentMatchId);
  const viewingScores = useSelector(selectViewingScores);
  console.log("Home", { currentMatchId });

  return (
    <div className="h-screen w-screen flex flex-col">
      <div className="grid grid-cols-3 w-full p-4 bg-teal-200 items-center">
        <div className="justify-self-start flex gap-4">
          <button onClick={clearSelectedMatch} className="hover:text-gray-600">
            Switch game
          </button>
          {currentMatchId !== undefined && (
            <button
              onClick={() => setViewingScores(!viewingScores)}
              className="hover:text-gray-600"
            >
              {viewingScores ? "Play" : "Scores"}
            </button>
          )}
        </div>
        <h2 className="justify-self-center text-4xl text-bold">Königsrufen</h2>
        <div className="justify-self-end">
          <button className="hover:text-gray-600" onClick={logout}>
            Logout
          </button>
        </div>
      </div>
      <div className="flex-grow p-4">
        {currentMatchId === undefined ? (
          <MatchPicker userId={userId} />
        ) : (
          <Match />
        )}
      </div>
    </div>
  );
};
