import React from "react";
import { Card } from "../shared/Card";

import {MyHandCard} from "../../../types/types";

interface Props {
  card: MyHandCard;
}

export const HandCard: React.FC<Props> = ({ card }) => {
  return (
    <Card
      onClick={() => card.onClick && card.onClick(card)}
      enabled={card.isEnabled}
      key={`me-handcard-${card.slug}`}
      slug={card.slug}
    />
  );
};
