import { ExtendedFirestoreInstance } from "react-redux-firebase";
import { nanoid } from "nanoid";
import { chunk, reverse, shuffle } from "lodash";

import { DECK, GameStage } from "../constants";
import { Card, Game, GamePlayer, MatchPlayer } from "../types/firestoreTypes";
import { cardPath, gamePath, gamePlayerPath } from "../firestorePaths";
import { HandCard } from "../types/types";

export const createGame = async ({
  matchId,
  gameIndex,
  players,
  firestore,
}: {
  matchId: string;
  gameIndex: number;
  players: MatchPlayer[];
  firestore: ExtendedFirestoreInstance;
}): Promise<string> => {
  console.log("createGame", { matchId, gameIndex, players });
  if (players === undefined) {
    throw new Error("No match players found, cannot create game");
  }
  const gameId = nanoid();
  const path = gamePath({ matchId, gameId });
  const game: Game = {
    matchId,
    id: gameId,
    gameIndex,
    stage: GameStage.Dealing,
  };
  // create game - TODO: possible race condition with multiple players creating
  await firestore.set<Game>(path, game);
  // deal cards
  const shuffledDeck = shuffle(DECK);
  const [talonCards, ...hands] = reverse(chunk(shuffledDeck, 12));

  // createGamePlayers
  await Promise.all(
    players.map((player) => {
      const gamePlayer: GamePlayer = {
        ...player,
        gameId,
        gamePosition: (player.seat + gameIndex) % 4,
      };
      return firestore.set<GamePlayer>(
        gamePlayerPath({ playerId: player.id, gameId, matchId }),
        gamePlayer
      );
    })
  );

  // create handCards
  await Promise.all(
    hands.map(async (handSlugs, i) => {
      const playerId = players[i].id;
      await Promise.all(
        handSlugs.map((slug, index) => {
          const cardId = nanoid();
          const path = cardPath({ matchId, gameId, cardId });
          const card: HandCard = {
            slug,
            playerId,
            gameId,
            matchId,
            id: cardId,
            isPutdown: false,
            isOriginalTalon: false,
            index, // only used for talon cards - should more properly be unique for deck
          };
          return firestore.set(path, card);
        })
      );
    })
  );

  // create Taloncards
  await Promise.all(
    talonCards.map((slug, index) => {
      const cardId = nanoid();
      const card: Card = {
        slug,
        gameId,
        matchId,
        id: cardId,
        isPutdown: false,
        isOriginalTalon: true,
        index,
      };
      const path = cardPath({ matchId, gameId, cardId });
      return firestore.set(path, card);
    })
  );
  await firestore.update<Game>(path, { stage: GameStage.Bidding });
  return gameId;
};
