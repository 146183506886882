import React from "react";

import { Button } from "@mui/material";

import { useMatch } from "../../hooks/useMatch";
import { PlayerNameIcon } from "../PlayerNameIcon";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { HandshakeOutlined } from "@mui/icons-material";
import { selectMatchGames, selectPlayersForMatch } from "../../store/selectors";
import { useSelector } from "react-redux";
import { useConnectMatchListing } from "../../hooks/useConnectMatchListing";

interface Props {
  matchId: string;
}

const ConnectMatchListing: React.FC<{ matchId: string }> = ({
  matchId,
  children,
}) => {
  useConnectMatchListing({ matchId });
  return <>{children}</>;
};

export const ConnectedMatchListing: React.FC<Props> = ({ matchId }) => {
  const { matchListing, joined, join } = useMatch({ matchId });
  const { setViewingScores } = useCurrentUser();
  const games = useSelector(selectMatchGames(matchId));
  const matchPlayers = useSelector(selectPlayersForMatch(matchId));

  console.log({ matchListing, joined, join, matchPlayers, games });
  if (matchListing === undefined || matchPlayers === undefined) {
    return <div>loading...</div>;
  }

  return (
    <div className="rounded-md bg-blue-50 p-4 flex flex-col gap-2">
      <div className="flex gap-2">
        {matchPlayers.map((p) => (
          <PlayerNameIcon human={p.human} name={p.name} key={p.id} />
        ))}
      </div>
      <div className="flex space-x-2">
        <HandshakeOutlined />
        <div>Hand {(games?.length ?? -1) + 1}</div>
      </div>
      <div className="flex gap-2">
        <Button onClick={join} variant="outlined">
          {joined ? `Play` : `Join`}
        </Button>
        {joined && (
          <Button
            onClick={async () => {
              await join();
              setViewingScores(true);
            }}
            variant="outlined"
          >
            Scores
          </Button>
        )}
      </div>
    </div>
  );
};

export const MatchListing: React.FC<Props> = ({ matchId }) => {
  console.log("MatchListing", { matchId });
  return (
    <ConnectMatchListing matchId={matchId}>
      <ConnectedMatchListing matchId={matchId} />
    </ConnectMatchListing>
  );
};
