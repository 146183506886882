import { CardSlug } from "../types/types";
import { sum } from "lodash";

import { parseCardSlug } from "./parseCardSlug";

import { Suit } from "../types/types";
import { TRUMP } from "../constants";

const getTripledPoints = ({
  suit,
  value,
}: {
  suit: Suit;
  value: number;
}): number => {
  switch (suit) {
    case TRUMP:
      switch (value) {
        case 22:
        case 21:
        case 1:
          // 4 1/3 * 3
          return 13;
        default:
          return 1;
      }
    default:
      switch (value) {
        case 8:
          // 4 1/3 * 3
          return 13;
        case 7:
          // 3 1/3 * 3
          return 10;
        case 6:
          // 2 1/3 * 3
          return 7;
        case 5:
          // 1 1/3 * 3
          return 4;
        default:
          return 1;
      }
  }
};

export const getCardPoints = (cards: CardSlug[]): number => {
  const tripledPoints = cards.map((c) => getTripledPoints(parseCardSlug(c)));
  return Math.round(sum(tripledPoints) / 3);
};
