import Button from "@mui/material/Button";
import React from "react";
import { useSelector } from "react-redux";
import {
  selectIAmAnnouncer,
  selectValidAnnouncements,
} from "../../store/selectors";
import { useAnnouncements } from "../../hooks/useAnnouncements";
import { getAnnouncementName } from "../../helpers/getAnnouncementName";
import { ActionHeaderText } from "./shared/ActionHeaderText";

export function AnnouncementPicker() {
  const { makeAnnouncement } = useAnnouncements();

  const validAnnouncements = useSelector(selectValidAnnouncements);
  const iAmActiveAnnouncer = useSelector(selectIAmAnnouncer);

  if (validAnnouncements === undefined) {
    return <div>loading</div>;
  }

  if (!iAmActiveAnnouncer) {
    return <div>Waiting for other players to make announcements</div>;
  }

  return (
    <div>
      <ActionHeaderText>Make an announcement or pass</ActionHeaderText>
      <div className="flex flex-wrap items-center justify-center gap-2">
        {validAnnouncements.map((a) => (
          <Button
            key={a.slug + a.base}
            variant="outlined"
            className="!rounded-full"
            onClick={() => makeAnnouncement(a)}
          >
            {getAnnouncementName(a)}
          </Button>
        ))}
      </div>
    </div>
  );
}
