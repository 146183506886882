import {GamePlayer, HandCard} from "../types/types";
import {isLoaded } from "react-redux-firebase";
import {useSelector} from "react-redux";
import {selectDeclarer, selectDeclarerHandCards} from "../store/selectors";

interface ReturnType {
  loaded: boolean;
  id: string | undefined;
  declarer: GamePlayer | undefined;
  name: string | undefined;
  human: boolean | undefined;
  handCards: HandCard[] | undefined
}

export const useDeclarer = (): ReturnType => {
  const declarer = useSelector(selectDeclarer)
  const handCards = useSelector(selectDeclarerHandCards)

  return {
    loaded: isLoaded(declarer),
    declarer,
    name: declarer?.name,
    human: declarer?.human,
    id: declarer?.id,
    handCards
  };
};
