import React from "react";
import { sortBy } from "lodash";
import Button from "@mui/material/Button";

import { useBids } from "../../../hooks/useBids";
import { BIDS } from "../../../constants";

export const BidPicker: React.FC = () => {
  const { validBids, makeBid, iAmActiveBidder } = useBids();

  if (validBids === undefined) {
    return <div>loading</div>;
  }

  if (!iAmActiveBidder) {
    return <div>Waiting for other players to bid</div>;
  }

  return (
    <div>
      <h2 className="text-lg font-bold mb-8">Make a bid</h2>
      <div className="flex flex-wrap items-center justify-center gap-2">
        {sortBy(
          BIDS.filter((b) => !b.ruferSubBid),
          ({ points }) => points
        ).map((bid) => (
          <Button
            key={bid.slug}
            variant="outlined"
            className="!rounded-full"
            disabled={!validBids.map(({ slug }) => slug).includes(bid.slug)}
            onClick={() => makeBid({ bid })}
          >
            {bid.name}
          </Button>
        ))}
      </div>
    </div>
  );
};
