import { TrickCard } from "../types/types";
import { groupBy, flatten } from "lodash";

import { getWinningCard } from "./getWinningCard";

interface Props {
  trickCards: TrickCard[];
  playerId: string;
}

export const getWonCards = ({ trickCards, playerId }: Props): TrickCard[] => {
  const tricks = groupBy(trickCards, "trickIndex");
  const wonTrickCards = Object.values(tricks).filter((t) => {
    const winningCard = getWinningCard(t);
    return winningCard.playerId === playerId;
  });
  return flatten(wonTrickCards);
};
