import { nanoid } from "nanoid";

import { announcementPath } from "../firestorePaths";

import { Announcement, MadeAnnouncement } from "../types/types";
import { useFirestore } from "react-redux-firebase";
import {
  selectActiveAnnouncer,
  selectCurrentGameId,
  selectCurrentMatchId,
  selectLastAnnouncement,
} from "../store/selectors";
import { useSelector } from "react-redux";

interface ReturnType {
  makeAnnouncement: (announcement: Announcement) => Promise<void>;
}

export const useAnnouncements = (): ReturnType => {
  const firestore = useFirestore();
  const currentGameId = useSelector(selectCurrentGameId);
  const currentMatchId = useSelector(selectCurrentMatchId);
  const lastAnnouncement = useSelector(selectLastAnnouncement);
  const activePlayer = useSelector(selectActiveAnnouncer);

  const makeAnnouncement = async (announcement: Announcement) => {
    console.log("makeAnnouncement", { lastAnnouncement });
    if (
      currentGameId === undefined ||
      currentMatchId === undefined ||
      activePlayer === undefined
    ) {
      return;
    }
    const announcementId = nanoid();
    const index = lastAnnouncement ? lastAnnouncement.index + 1 : 0;
    const path = announcementPath({
      gameId: currentGameId,
      matchId: currentMatchId,
      announcementId,
    });
    const madeAnnouncement: MadeAnnouncement = {
      ...announcement,
      playerId: activePlayer.id,
      gameId: currentGameId,
      matchId: currentMatchId,
      index,
    };
    await firestore.set<MadeAnnouncement>(path, madeAnnouncement);
  };

  return {
    makeAnnouncement,
  };
};
