import React from "react";
import classNames from "classnames";

import { Size } from "../../../types/types";

interface Props {
  slug: string;
  landscape?: boolean;
  enabled?: boolean;
  onClick?: (slug: string) => void;
  size?: Size;
}

export const Card: React.FC<Props> = ({
  slug = undefined,
  enabled = false,
  landscape = false,
  size = Size.M,
  onClick,
}): React.ReactElement => {
  let fileName: string;
  if (landscape) {
    fileName = `landscape_${slug}.jpg`;
  } else {
    fileName = `${slug}.jpg`;
  }

  return (
    <button
      type="button"
      onClick={() => enabled && onClick && slug && onClick(slug)}
    >
      {/* https://stackoverflow.com/questions/45334874/load-images-based-on-dynamic-path-in-reactjs */}
      <img
        src={process.env.PUBLIC_URL + "/cards/" + fileName}
        alt={slug}
        className={classNames(
          "outline rounded-lg outline-4 -outline-offset-8 h-auto",
          { "w-48": size === Size.XL },
          { "w-36": size === Size.L },
          { "w-24": size === Size.M },
          { "w-16": size === Size.S },
          { "w-12": size === Size.XS },
          { "w-6 rounded-sm outline-1": size === Size.XXS },
          { "outline-black": !enabled },
          { "outline-green-500": enabled },
          { "cursor-default": onClick === undefined }
        )}
      />
    </button>
  );
};
