import { Chip } from "@mui/material";
import React from "react";
import { useGamePlayer } from "../../hooks/useGamePlayer";
import { useSelector } from "react-redux";
import {
  selectDeclarer,
  selectForehand,
  selectGameStage,
  selectPartner,
  selectPartnerKnown,
  selectPlayerAnnouncements,
} from "../../store/selectors";
import { GameStage } from "../../constants";
import { getAnnouncementName } from "../../helpers/getAnnouncementName";
import { PlayerNameIcon } from "../PlayerNameIcon";
import { ForehandIcon } from "./shared/icons/ForehandIcon";
import { DeclarerIcon } from "./shared/icons/DeclarerIcon";
import { PartnerIcon } from "./shared/icons/PartnerIcon";

interface Props {
  playerId: string;
}

export const PlayerDetails: React.FC<Props> = ({ playerId }) => {
  const { bids, name, human } = useGamePlayer({ playerId });
  const gameStage = useSelector(selectGameStage);
  const forehand = useSelector(selectForehand);
  const partner = useSelector(selectPartner);
  const declarer = useSelector(selectDeclarer);
  const partnerKnown = useSelector(selectPartnerKnown);
  const announcements = useSelector(selectPlayerAnnouncements(playerId));
  if (name === undefined || human === undefined) {
    return <div>loading</div>;
  }
  return (
    <div className="flex space-x-6 items-center">
      <PlayerNameIcon
        name={name}
        human={human}
        className="text-lg font-bold p-4"
      />
      <div className="flex space-x-2">
        {forehand?.id === playerId && <ForehandIcon />}
        {declarer?.id === playerId && <DeclarerIcon />}
        {partner?.id === playerId && partnerKnown && <PartnerIcon />}
        {gameStage === GameStage.Bidding &&
          bids?.map(({ slug, name, index }) => (
            <Chip key={`${slug}-${index}`} label={name} />
          ))}
        {gameStage === GameStage.Announcements &&
          announcements?.map((a) => (
            <Chip key={`${a.slug}-${a.index}`} label={getAnnouncementName(a)} />
          ))}
      </div>
    </div>
  );
};
