import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";
// https://stackoverflow.com/questions/69045324/react-redux-firebase-and-redux-firestore-integration
import firebase from "firebase/compat/app";
import firebaseConfig from "./config/firebase.json";

import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/database";
import React from "react";
import { render } from "react-dom";

import "./index.css";
import { App } from "./App";
import { StoreState } from "./types/types";
import { reducer } from "./store/reducer";

export const rfConfig = {
  userProfile: "users", // root that user profiles are written to
  useFirestoreForProfile: true, // Save profile to Firestore instead of Real Time Database
  useFirestoreForStorageMeta: true, // Metadata associated with storage file uploads goes to Firestore
};

// Initialize firebase instance
firebase.initializeApp(firebaseConfig);

const EMPTY_STATE: StoreState = {
  currentUserId: null,
  firestore: {
    data: {
      matches: {},
      matchPlayers: {},
      games: {},
      gamePlayers: {},
      bids: {},
      announcements: {},
      cards: {},
    },
    ordered: {
      users: undefined,
      matches: undefined,
      matchPlayers: undefined,
      games: undefined,
      gamePlayers: undefined,
      announcements: undefined,
      cards: undefined,
      bids: undefined,
    },
  },
  localPutdowns: [],
};

// Create store with reducers and initial state
const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
  preloadedState: EMPTY_STATE,
});

render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider
      firebase={firebase}
      config={rfConfig}
      dispatch={store.dispatch}
      createFirestoreInstance={createFirestoreInstance}
      initializeAuth
    >
      <App />
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById("root")
);
