import React from "react";
import { useCurrentMatch } from "../hooks/useCurrentMatch";
import { useSelector } from "react-redux";
import {
  selectCurrentMatchGameOutcomes,
  selectCurrentMatchGames,
} from "../store/selectors";

// const GameScore: React.FC<{ gameId: string; matchId: string }> = ({
//   gameId,
//   matchId,
// }) => {
//   useConnectGame({ matchId, gameId });
//   const game = useSelector(selectGame(matchId, gameId));
//   const players = useSelector(selectGamePlayers(matchId, gameId));
//   const scores = useSelector(selectTeamScores(matchId, gameId));
//   return null;
// };
//
export const MatchScoreBoard: React.FC = () => {
  const { match } = useCurrentMatch();
  const games = useSelector(selectCurrentMatchGames);
  console.log({ games, match });
  // if (games === undefined || match === undefined) {
  //   return null;
  // }
  const outcomes = useSelector(selectCurrentMatchGameOutcomes);
  console.log({ outcomes });
  return <div>Match score board</div>;
};
