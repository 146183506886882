import { useSelector } from "react-redux";
import {
  selectAnnouncements,
  selectGamePlayer,
} from "../../../store/selectors";
import { AnnouncementSlug } from "../../../constants";
import { LetterIcon } from "../shared/icons/LetterIcon";
import { red } from "@mui/material/colors";
import React from "react";
import { sortBy } from "lodash";
import classNames from "classnames";
import { MadeAnnouncement } from "../../../types/types";
import { getAnnouncementName } from "../../../helpers/getAnnouncementName";
import { PlayerNameIcon } from "../../PlayerNameIcon";

const AnnouncementInfo: React.FC<{ announcement: MadeAnnouncement }> = ({
  announcement,
}) => {
  const player = useSelector(selectGamePlayer(announcement.playerId));
  const name = getAnnouncementName(announcement);

  if (player === undefined) {
    return null;
  }

  return (
    <div className="flex gap-2 items-center">
      <div className="font-bold">{name}</div>
      ( <PlayerNameIcon name={player.name} human={player.human} /> )
    </div>
  );
};

export const AnnouncementDetails = () => {
  const announcements = useSelector(selectAnnouncements);
  const nonPassAnnouncements = announcements?.filter(
    (a) => a.slug !== AnnouncementSlug.Pass
  );
  if (nonPassAnnouncements === undefined || nonPassAnnouncements.length === 0) {
    return null;
  }
  return (
    <div className="flex gap-4">
      <LetterIcon color={red[500]} title="Announcements" />
      <div
        className={classNames("flex flex-col gap-2", {
          "mt-2": nonPassAnnouncements.length === 1,
        })}
      >
        {sortBy(nonPassAnnouncements, (a) => a.index).map((a) => (
          <AnnouncementInfo
            key={a.slug + a.base + a.playerId}
            announcement={a}
          />
        ))}
      </div>
    </div>
  );
};
