import { useFirestoreConnect, WhereOptions } from "react-redux-firebase";

interface Props {
  matchId: string;
  gameId: string;
}

export const useConnectGame = ({
  matchId,
  gameId,
}: Props): "success" | "error" => {
  const where: WhereOptions[] = [
    ["matchId", "==", matchId],
    ["gameId", "==", gameId],
  ];
  const listeners = [
    { collectionGroup: "gamePlayers", where, storeAs: "gamePlayers" },
    { collectionGroup: "bids", where, storeAs: "bids" },
    { collectionGroup: "announcements", where, storeAs: "announcements" },
    { collectionGroup: "cards", where, storeAs: "cards" },
  ];

  try {
    useFirestoreConnect(listeners);
    return "success";
  } catch (e) {
    return "error";
  }
};
