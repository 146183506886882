import React from "react";

import { useCurrentGame } from "../../../hooks/useCurrentGame";
import { useDeclarer } from "../../../hooks/useDeclarer";
import { Card } from "../shared/Card";
import { KING_SLUGS } from "../../../constants";
import { useSelector } from "react-redux";
import { selectIAmDeclarer } from "../../../store/selectors";

export const KingPicker: React.FC = () => {
  const { setPickedKing } = useCurrentGame();
  const { declarer } = useDeclarer();
  const iAmDeclarer = useSelector(selectIAmDeclarer);

  // should never happen
  if (declarer === undefined) {
    return <div>Loading declarer</div>;
  }

  if (!iAmDeclarer) {
    return <div>Waiting for {declarer.name} to pick a King</div>;
  }

  return (
    <div className="flex space-x-2 items-center justify-center h-full">
      {KING_SLUGS.map((kingSlug) => (
        <Card
          key={kingSlug}
          slug={kingSlug}
          onClick={() => setPickedKing(kingSlug)}
          enabled
        />
      ))}
    </div>
  );
};
