import { Card } from "../types/firestoreTypes";

export enum ACTION_TYPE {
  SET_CURRENT_USER_ID = "setCurrentUserId",
  CLEAR_CURRENT_USER_ID = "clearCurrentUserId",
  ADD_LOCAL_PUTDOWN = "addLocalPutdown",
  REMOVE_LOCAL_PUTDOWN = "removeLocalPutdown",
  CLEAR_LOCAL_PUTDOWNS = "clearLocalPutdowns",
}

type Action<T> = (payload: T) => {
  type: ACTION_TYPE;
  payload: T;
};

export const setCurrentUserId: Action<string> = (userId) => ({
  type: ACTION_TYPE.SET_CURRENT_USER_ID,
  payload: userId,
});

export const clearCurrentUserId = {
  type: ACTION_TYPE.CLEAR_CURRENT_USER_ID,
};

export const addLocalPutdown: Action<Card> = (card) => ({
  type: ACTION_TYPE.ADD_LOCAL_PUTDOWN,
  payload: card,
});

export const removeLocalPutdown: Action<Card> = (card) => ({
  type: ACTION_TYPE.REMOVE_LOCAL_PUTDOWN,
  payload: card,
});

export const clearLocalPutdowns = {
  type: ACTION_TYPE.CLEAR_LOCAL_PUTDOWNS,
};
