import { useCallback } from "react";
import {
  Game,
  RuferOptionSlug,
  KingSlug,
  Card,
  GameStage,
} from "../types/types";
import { isLoaded, useFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";
import {
  selectCurrentGame,
  selectCurrentGamePath,
  selectGameStage,
  selectCurrentGamePutdowns,
} from "../store/selectors";

interface ReturnType {
  loaded: boolean;
  game: Game | undefined;
  ruferOption: RuferOptionSlug | undefined;
  setRuferOption: (ruferOption: RuferOptionSlug) => Promise<void>;
  pickedKing: KingSlug | undefined;
  setPickedKing: (kingSlug: KingSlug) => Promise<void>;
  updateGame: (data: Partial<Game>) => Promise<void>;
  putdowns: Card[];
  // positionedPlayerIds:
  //   | {
  //       west: string;
  //       north: string;
  //       east: string;
  //       south: string;
  //     }
  //   | undefined;
  stage: GameStage | undefined;
}

export const useCurrentGame = (): ReturnType => {
  const firestore = useFirestore();

  const game = useSelector(selectCurrentGame);
  const path = useSelector(selectCurrentGamePath);
  const putdowns = useSelector(selectCurrentGamePutdowns);
  const stage = useSelector(selectGameStage);
  // const positionedPlayerIds = usePositionedGamePlayerIds();

  const updateGame = useCallback(
    async (update: Partial<Game>) => {
      if (path === undefined) {
        return;
      }
      await firestore.update(path, update);
    },
    [firestore, path]
  );

  const setRuferOption = useCallback(
    async (ruferOption: RuferOptionSlug) => {
      await updateGame({ ruferOption });
    },
    [updateGame]
  );

  const setPickedKing = useCallback(
    async (pickedKing: KingSlug) => {
      await updateGame({ pickedKing });
    },
    [updateGame]
  );
  return {
    loaded: isLoaded(game),
    game: game as Game | undefined,
    ruferOption: game?.ruferOption,
    setRuferOption,
    pickedKing: game?.pickedKing,
    setPickedKing,
    updateGame,
    putdowns: putdowns ?? [],
    // positionedPlayerIds,
    stage,
  };
};
