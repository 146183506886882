import React from "react";
import { useSelector } from "react-redux";
import {
  selectCurrentGameBids,
  selectPlayerName,
} from "../../../store/selectors";
import { sortBy } from "lodash";
import { MadeBid } from "../../../types/firestoreTypes";
import { enrichMadeBid } from "../../../helpers/enrichMadeBid";

const BidLog: React.FC<{ bid: MadeBid }> = ({ bid }) => {
  const playerName = useSelector(selectPlayerName(bid.playerId));
  const { name: bidName } = enrichMadeBid(bid);
  const bidText = bidName === "Pass" ? "passes" : `bids ${bidName}`;
  return (
    <div>
      {playerName} {bidText}
    </div>
  );
};

export const BidsLog: React.FC = () => {
  const bids = useSelector(selectCurrentGameBids);

  if (bids === undefined) {
    return null;
  }

  return (
    <>
      {sortBy(bids, (b) => b.index).map((bid) => (
        <BidLog bid={bid} key={bid.index} />
      ))}
    </>
  );
};
