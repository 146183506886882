import { nanoid } from "nanoid";
import { useMemo, useCallback } from "react";

import { bidPath } from "../firestorePaths";

import { BidDetails, MadeBid, GamePlayer } from "../types/types";
import { BIDS, BidSlug } from "../constants";
import { useGamePlayers } from "./useGamePlayers";
import { isLoaded, useFirestore } from "react-redux-firebase";
import {
  selectBiddingFinished,
  selectCurrentGameBids,
  selectCurrentGameId,
  selectCurrentMatchId,
  selectMyUserId,
  selectLastBid,
  selectWinningBid,
  selectFirestoreOrderedState,
} from "../store/selectors";
import { useSelector } from "react-redux";

interface ReturnType {
  madeBids: MadeBid[] | undefined;
  getValidBids: (args: { playerId: string }) => BidDetails[] | undefined;
  lastBid: MadeBid | undefined;
  makeBid: (args: { bid: BidDetails }) => Promise<void>;
  loaded: boolean;
  finished: boolean;
  winningBid: MadeBid | undefined;
  activePlayer: GamePlayer | undefined;
  declarerId: string | undefined;
  iAmActiveBidder: boolean;
  validBids: BidDetails[] | undefined;
}

export const useBids = (): ReturnType => {
  const firestore = useFirestore();
  const currentGameId = useSelector(selectCurrentGameId);
  const currentMatchId = useSelector(selectCurrentMatchId);
  const lastBid = useSelector(selectLastBid);
  const { getNextPlayerFromId, forehand } = useGamePlayers();
  const madeBids = useSelector(selectCurrentGameBids);
  const currentUserId = useSelector(selectMyUserId);
  const winningBid = useSelector(selectWinningBid);
  const orderedState = useSelector(selectFirestoreOrderedState);
  console.log("useBids", {
    madeBidsLoaded: isLoaded(madeBids),
    lastBidLoaded: isLoaded(lastBid),
    orderedStateLoaded: isLoaded(orderedState),
    madeBids,
    lastBid,
  });

  const activePlayer = useMemo(() => {
    if (!isLoaded(madeBids)) {
      return undefined;
    }
    if (lastBid === undefined) {
      return forehand;
    }

    return getNextPlayerFromId(lastBid.playerId);
  }, [getNextPlayerFromId, lastBid, forehand, madeBids]);

  const finished = useSelector(selectBiddingFinished);

  // useEffect(() => {
  //   if (
  //     finished &&
  //     gameStage === GameStage.Bidding &&
  //     winningBid !== undefined &&
  //     winningBid.slug !== BidSlug.Pass
  //   ) {
  //     const newStage: GameStage = (() => {
  //       switch (winningBid.slug) {
  //         case BidSlug.Rufer:
  //           return GameStage.RuferOptions;
  //         case BidSlug.Solo:
  //         case BidSlug.BesserRufer:
  //           return GameStage.PickKing;
  //         case BidSlug.Dreier:
  //           return GameStage.Talon;
  //         case BidSlug.SoloDreier:
  //         case BidSlug.Piccolo:
  //         case BidSlug.Bettel:
  //           return GameStage.Announcements;
  //       }
  //     })();
  //
  //     updateGame({ stage: newStage });
  //   }
  // }, [finished, updateGame, gameStage, winningBid]);

  const getValidBids = useCallback(
    ({ playerId }: { playerId: string }): BidDetails[] | undefined => {
      if (forehand === undefined || !isLoaded(madeBids)) {
        return undefined;
      }

      if (winningBid === undefined) {
        // first bid (we don't allow forehand 'pass')
        return BIDS.filter(
          ({ slug, ruferSubBid }) => slug !== BidSlug.Pass && !ruferSubBid
        );
      }

      return BIDS.filter(({ slug, points }) => {
        // not first bid so pass allowed
        if (slug === BidSlug.Pass) {
          return true;
        }

        if (playerId === forehand.id) {
          return points >= winningBid.points;
        }
        return points > winningBid.points;
      });
    },
    [forehand, winningBid, madeBids]
  );

  const makeBid = async ({ bid }: { bid: BidDetails }) => {
    console.log("makeBid", { bid });
    if (
      currentGameId === undefined ||
      currentMatchId === undefined ||
      activePlayer === undefined
    ) {
      return;
    }
    const bidId = nanoid();
    const index = lastBid ? lastBid.index + 1 : 0;
    const path = bidPath({
      gameId: currentGameId,
      matchId: currentMatchId,
      bidId,
    });
    const madeBid: MadeBid = {
      ...bid,
      playerId: activePlayer.id,
      gameId: currentGameId,
      matchId: currentMatchId,
      index,
    };
    await firestore.set<MadeBid>(path, madeBid);
  };

  return {
    getValidBids,
    makeBid,
    lastBid,
    loaded: isLoaded(madeBids),
    madeBids,
    finished,
    winningBid,
    activePlayer,
    declarerId: winningBid?.playerId,
    iAmActiveBidder: activePlayer?.id === currentUserId,
    validBids:
      activePlayer === undefined
        ? undefined
        : getValidBids({ playerId: activePlayer.id }),
  };
};
